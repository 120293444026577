<template>
    
    <v-card class="container-modul">
        <v-card-title class="pa-0" ><v-icon class="mr-2" color="blackIcon">mdi-account-details</v-icon>Personal Info</v-card-title>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="personalInfo.name"
                            label="Employee Name"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                            maxlength="100"
                        >
                        </v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="3"
                        >
                        <v-text-field
                            v-model="personalInfo.ic"
                            label="IC Number"
                            outlined
                            dense
                            hide-details="auto"
                            readonly
                        ></v-text-field>
                        </v-col>
                        
                        <v-col
                        class="d-flex"
                        cols="12"
                        sm="3"
                        >
                        <v-select
                            v-model="personalInfo.gender"
                            :items="gender"
                            item-text="name"
                            item-value="value"
                            label="Gender"
                            dense
                            outlined
                            hide-details="auto"
                        ></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="personalInfo.address"
                            label="Address"
                            outlined
                            dense
                            hide-details="auto"
                            maxlength="250"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="personalInfo.postalcode"
                            label="Postal Code"
                            outlined
                            dense
                            hide-details="auto"
                            @keypress="isNumber($event)"
                            maxlength="5"                            
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="personalInfo.city"
                            label="City"
                            outlined
                            dense
                            hide-details="auto"
                            maxlength="50"
                        ></v-text-field>
                        </v-col>
                        
                        <v-col
                        class="d-flex"
                        cols="12"
                        sm="6"
                        >
                        <v-select
                        v-model="personalInfo.stateid"
                        :items="state"
                        item-text="name"
                        item-value="idstate"
                        label="State"
                        dense
                        outlined
                        hide-details="auto"
                        ></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="personalInfo.hp"
                            label="Tel No (H/P)"
                            outlined
                            dense
                            hide-details="auto"
                            @keypress="isNumber($event)"
                            @input="checkLength($event)"
                            :rules="[rules.required, rules.counter]"
                            maxlength="20"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="personalInfo.homeno"
                            label="Tel No (Home)"
                            outlined
                            dense
                            @keypress="isNumber($event)"
                            hide-details="auto"
                            maxlength="10"
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="personalInfo.email1"
                            :rules="[rules.email]"
                            label="Office Email"
                            outlined
                            dense
                            hide-details="auto"
                            maxlength="50"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="personalInfo.email2"
                            :rules="[rules.email]"
                            label="Other Email"
                            outlined
                            dense
                            hide-details="auto"
                            maxlength="50"
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col
                        cols="12"
                        sm="3"
                        >
                        <v-menu
                        v-model="modaldob"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="personalInfo.datebirth"
                                label="Date Of Birth"
                                prepend-icon="mdi-calendar"
                                outlined
                                dense
                                hide-details="auto"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="personalInfo.datebirth"
                            @input="modaldob = false"
                            ></v-date-picker>
                        </v-menu>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="3"
                        >
                        <v-text-field
                            v-model="personalInfo.age"
                            label="Age"
                            outlined
                            dense
                            hide-details="auto"
                            readonly
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="3"
                        >
                        <v-text-field
                            v-model="personalInfo.placeofbirth"
                            label="Place Of Birth"
                            outlined
                            dense
                            hide-details="auto"
                            maxlength="100"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        class="d-flex"
                        cols="12"
                        sm="3"
                        >
                        <v-select
                        v-model="personalInfo.maritalstatus"
                        :items="maritalStatus"
                        label="Marital Status"
                        dense
                        outlined
                        hide-details="auto"
                        ></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <span>Driving License Type:</span>
                            <v-row>
                                <v-col cols="6" sm="2">
                                <v-checkbox
                                v-model="personalInfo.drivinglicense_b2"
                                label="B2"
                                value="b2"
                                hide-details
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="6" sm="2">
                                <v-checkbox
                                v-model="personalInfo.drivinglicense_d"
                                label="D"
                                value="d"
                                hide-details
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="6" sm="2">
                                <v-checkbox
                                v-model="personalInfo.drivinglicense_da"
                                label="DA"
                                value="da"
                                hide-details
                                ></v-checkbox>
                            </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row class="pb-5">
                        <v-col
                        cols="12"
                        sm="3"
                        >
                        <v-btn
                        class="mr-4"
                        color="primary"
                        @click="updatePersonalInfo()"
                        >
                        Update
                        </v-btn>
                        <!-- <v-btn
                        color="error"
                        >
                        Reset
                        </v-btn> -->
                        </v-col>
                        
                    </v-row>

                    
                    <v-divider v-if="personalInfo.empmartial != 'Single'"></v-divider>

                    <!-- <v-row>
                        <template>
                            <v-container>
                                <v-data-table
                                    :headers="headersSpouse"
                                    :items="spouse"
                                    :items-per-page="5"
                                    class="elevation-1"
                                >
                                    <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>Spouse's Name</v-toolbar-title>
                                        <v-divider
                                        class="mx-4"
                                        inset
                                        vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-dialog
                                        v-model="dialogSpouse"
                                        max-width="500px"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            color="primary"
                                            dark
                                            class="mb-2"
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon>mdi-plus</v-icon>
                                            New Spouse
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title>
                                            <span class="text-h5">{{ formTitleSpouse }}</span>
                                            </v-card-title>

                                            <v-card-text>
                                            <v-container>
                                                <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                    v-model="editedItemSpouse.spousename"
                                                    label="Spouse name"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    ></v-text-field>
                                                </v-col>
                                                </v-row>
                                            </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="closeSpouse"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="saveSpouse"
                                            >
                                                Save
                                            </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                        <v-dialog v-model="dialogDeleteSpouse" max-width="500px">
                                        <v-card>
                                            <v-card-title class="text-h5">Are you sure you want to delete?</v-card-title>
                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="closeDeleteSpouse">Cancel</v-btn>
                                            <v-btn color="blue darken-1" text @click="deleteItemConfirmSpouse">OK</v-btn>
                                            <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editItemSpouse(item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteItemSpouse(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                    <v-btn
                                        color="primary"
                                        @click="initializeSpouse"
                                    >
                                        Reset
                                    </v-btn>
                                    </template>
                                </v-data-table>
                            </v-container>
                        </template>
                    </v-row> -->
                    <v-container v-if="personalInfo.empmartial != 'Single'">

                        <v-card-subtitle class="pa-0" >
                            <v-icon color="dark-grey" class="mr-1">mdi-human-male-female</v-icon> Spouse's Info
                        </v-card-subtitle>

                        <v-row class="pt-2">
                            <v-col cols="12">
                                <template>
                                <v-data-table
                                    :headers="headersSpouse"
                                    :items="spouse"
                                    :items-per-page="5"
                                    class="elevation-1"
                                >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <!-- <v-toolbar-title>Spouse's Information</v-toolbar-title> -->

                                        <v-spacer></v-spacer>
                                        <v-dialog
                                        v-model="dialogSpouse"
                                        max-width="400px"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            color="primary"
                                            dark
                                            class="mb-2"
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon>mdi-plus</v-icon>
                                            New Spouse
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title class="justify-center title-bar-modul">
                                            <span class="text-h5">Spouse</span>
                                            </v-card-title>
                                            <v-card-subtitle>
                                            <v-row class="pt-5">
                                                <v-col
                                                    cols="12"
                                                >
                                                    <v-text-field
                                                    v-model="editedItemSpouse.spousename"
                                                    label="Spouse name"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            </v-card-subtitle>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="closeSpouse"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="saveSpouse"
                                            >
                                                Save
                                            </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                        <v-dialog v-model="dialogDeleteSpouse" max-width="500px">
                                        <v-card>
                                            <v-card-title class="justify-center title-bar-modul">
                                                <h4>Delete Spouse Name</h4>
                                            </v-card-title>
                                            <v-simple-table>
                                                <tbody>
                                                    <tr>
                                                        <td class="font-weight-bold" width="150px">Name :</td>
                                                        <td>{{ editedItemSpouse.spousename }}</td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" outlined @click="closeDeleteSpouse">Cancel</v-btn>
                                            <v-btn color="red" outlined @click="deleteItemConfirmSpouse">Delete</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon
                                            small
                                            class="mr-2 green--text"
                                            @click="editItemSpouse(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            small
                                            class="red--text"
                                            @click="deleteItemSpouse(item)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                </template>
                                
                                </v-data-table>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                    



                    <!-- <v-row>
                        <template>
                            <v-container>
                                <v-data-table
                                    :headers="headersChild"
                                    :items="child"
                                    :items-per-page="5"
                                    class="elevation-1"
                                >
                                    <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>Child's Name</v-toolbar-title>
                                        <v-divider
                                        class="mx-4"
                                        inset
                                        vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-dialog
                                        v-model="dialogChild"
                                        max-width="500px"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            color="primary"
                                            dark
                                            class="mb-2"
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon>mdi-plus</v-icon>
                                            New Child
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title>
                                            <span class="text-h5">Child's Information</span>
                                            </v-card-title>

                                            <v-card-text>
                                            <v-container>
                                                <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                    v-model="editedItemChild.childrenname"
                                                    label="Child name"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    ></v-text-field>
                                                </v-col>
                                                </v-row>
                                            </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="closeChild"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="saveChild"
                                            >
                                                Save
                                            </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                        <v-dialog v-model="dialogDeleteChild" max-width="500px">
                                        <v-card>
                                            <v-card-title class="text-h5">Are you sure you want to delete?</v-card-title>
                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="closeDeleteChild">Cancel</v-btn>
                                            <v-btn color="blue darken-1" text @click="deleteItemConfirmChild">OK</v-btn>
                                            <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editItemChild(item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteItemChild(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                    <v-btn
                                        color="primary"
                                        @click="initializeChild"
                                    >
                                        Reset
                                    </v-btn>
                                    </template>
                                </v-data-table>
                            </v-container>
                        </template>
                    </v-row> -->

                    <v-container v-if="personalInfo.empmartial != 'Single'">

                        <v-card-subtitle class="pa-0" >
                            <v-icon color="dark-grey" class="mr-1">mdi-human-male-female-child</v-icon> Child's Info
                        </v-card-subtitle>

                        <v-row class="pt-5">
                            <v-col cols="12">
                                <template>
                                <v-data-table
                                    :headers="headersChild"
                                    :items="child"
                                    :items-per-page="5"
                                    class="elevation-1"
                                >
                                    <template v-slot:top>
                                        <v-toolbar
                                            flat
                                        >
                                            <!-- <v-toolbar-title>Child's Information</v-toolbar-title> -->
                                            <v-spacer></v-spacer>
                                            <v-dialog
                                            v-model="dialogChild"
                                            max-width="500px"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="primary"
                                                dark
                                                class="mb-2"
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                <v-icon>mdi-plus</v-icon>
                                                New Child
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title class="justify-center title-bar-modul">
                                                <span class="text-h5">Child</span>
                                                </v-card-title>
                                                <v-card-subtitle>
                                                <v-row class="pt-5">
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-text-field
                                                        v-model="editedItemChild.childrenname"
                                                        label="Child name"
                                                        dense
                                                        outlined
                                                        hide-details="auto"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                </v-card-subtitle>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="closeChild"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="saveChild"
                                                >
                                                    Save
                                                </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                            </v-dialog>
                                            <v-dialog v-model="dialogDeleteChild" max-width="500px">
                                            <v-card>
                                                <v-card-title class="justify-center title-bar-modul">
                                                    <h4>Delete Child Name</h4>
                                                </v-card-title>
                                                <v-simple-table>
                                                    <tbody>
                                                        <tr>
                                                            <td class="font-weight-bold" width="150px">Name :</td>
                                                            <td>{{ editedItemChild.childrenname }}</td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="primary" outlined @click="closeDeleteChild">Cancel</v-btn>
                                                <v-btn color="red" outlined @click="deleteItemConfirmChild">Delete</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                            </v-dialog>
                                        </v-toolbar>
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon
                                            small
                                            class="mr-2 green--text"
                                            @click="editItemChild(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            small
                                            class="red--text"
                                            @click="deleteItemChild(item)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                        </template>
                                
                                </v-data-table>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>

                    

                    <!-- <v-row>
                        <v-container>
                            <v-card>
                                <v-col>
                                <span>Driving License Type:</span>
                                    <v-checkbox
                                    v-model="selected"
                                    label="B"
                                    value="B"
                                    hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                    v-model="selected"
                                    label="B1"
                                    value="B1"
                                    hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                    v-model="personalInfo.drivinglicense_b2"
                                    label="B2"
                                    value="b2"
                                    hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                    v-model="personalInfo.drivinglicense_d"
                                    label="D"
                                    value="d"
                                    hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                    v-model="personalInfo.drivinglicense_da"
                                    label="DA"
                                    value="da"
                                    hide-details
                                    ></v-checkbox>
                                    <v-checkbox
                                    v-model="selected"
                                    label="Others"
                                    value="Others"
                                    hide-details
                                    ></v-checkbox>
                                </v-col>
                            </v-card>
                        </v-container>
                    </v-row> -->

                </v-container>
            </v-form>


            <!-- Snackbar for update message -->
            <template>
              <div class="text-center">
                  <v-snackbar
                  v-model="snackbar"
                  :timeout="timeout"
                  :color="messagecolor"
                  >
                  {{ messagetext }}

                  <template v-slot:action="{ attrs }">
                      <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                      >
                      Close
                      </v-btn>
                  </template>
                  </v-snackbar>
              </div>
            </template>


    </v-card>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    props: ['mode','personalInfo','spouse','child'],
    data: () => ({
        state: [],
        dob: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modaldob: false,
        maritalStatus: ['Married', 'Single', 'Divorced', 'Widowed'],
        gender: [
            {
                name: 'Male',
                value: 'M'
            },
            {
                name: 'Female',
                value: 'F'
            }
        ],

        // personalInfo: {
        //   name:'',
        //   noIc:'',
        //   adress:'',
        //   postalCode:'',
        //   city:'',
        //   state:'',
        //   phone:'',
        //   homePhone:'',
        //   email1:'',
        //   email2:'',
        //   age:'',
        //   dateBirth:'',
        //   placeBirth:'',
        //   maritalStatus:'',
        //   drivinglicense_b2:'',
        //   drivinglicense_d:'',
        //   drivinglicense_da:'',


        // },
        
        //spouse
        dialogSpouse: false,
        dialogDeleteSpouse: false,
        headersSpouse: [
            {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'spousename',
            },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        // spouse: [],
        editedIndexSpouse: -1,
        editedItemSpouse: {
            id: '',
            idemp: '',
            spousename: '',
        },
        defaultItemSpouse: {
            id: '',
            idemp: '',
            spousename: '',
        },

        //children
        dialogChild: false,
        dialogDeleteChild: false,
        headersChild: [
            {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'childrenname',
            },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        // child: [],
        editedIndexChild: -1,
        editedItemChild: {
            id: '',
            idemp: '',
            childrenname: '',
        },
        defaultItemChild: {
            id: '',
            idemp: '',
            childrenname: '',
        },


        // Snackbar message after update data
        snackbar: false,
        timeout: 3000,
        messagecolor: '',
        messagetext: '',

        // Rules Setting
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value?.length >= 10 || 'Min 10 numbers',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
        
        
    }),

    // computed: {
    //   formTitleSpouse () {
    //     return this.editedIndexSpouse === -1 ? 'New Spouse' : 'Edit Spouse'
    //   },

    //   formTitleChild () {
    //     return this.editedIndexSpouse === -1 ? 'New Child' : 'Edit Child'
    //   }, 

    // },

    // watch: {
    //   dialogSpouse (val) {
    //     val || this.closeSpouse()
    //   },
    //   dialogDeleteSpouse (val) {
    //     val || this.closeDeleteSpouse()
    //   },
    //   dialogChild (val) {
    //     val || this.closeChild()
    //   },
    // },

    // created () {
    //   this.initializeSpouse()
    //   this.initializeChild()
    // },
    
    methods: {
      getPersonalInfo(){
        axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=2&id='+this.$store.getters.user.idEmp,{
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
        })
            .then((response) => {
                this.personalInfo = response.data.info;
                if(this.personalInfo.dateBirth != null || this.personalInfo.dateBirth != ''){
                    this.personalInfo.dateBirth = this.personalInfo.dateBirth.split('T')[0];
                }
            })
            .catch(error => {
                console.log(error);
            })
      },

      getSpouseList(){
          axios.get(process.env.VUE_APP_API_URL + 'employees/staffspouse',{
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.token,
              }
          })
          .then((response) => {
              this.spouse = response.data;
              // console.log(this.leaves);
          })
          .catch(error => {
              console.log(error);
          })
      },

      getChildList(){
          axios.get(process.env.VUE_APP_API_URL + 'employees/staffkid',{
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.token,
              }
          })
          .then((response) => {
              this.child = response.data;
              // console.log(this.leaves);
          })
          .catch(error => {
              console.log(error);
          })
      },

      getState(){
        axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
        })
        .then((response) => {
            this.state = response.data.states;
            // console.log(this.state);
        })
        .catch(error => {
            console.log(error);
        })

      },

      initializeSpouse () {
        this.spouse = []
      },

      //child
      initializeChild () {
        this.child = []
      },
    
      //spouse  
      editItemSpouse (item) {
        this.editedIndexSpouse = this.spouse.indexOf(item)
        this.editedItemSpouse = Object.assign({}, item)
        this.dialogSpouse = true
      },

      deleteItemSpouse (item) {
        this.editedIndexSpouse = this.spouse.indexOf(item)
        this.editedItemSpouse = Object.assign({}, item)
        this.dialogDeleteSpouse = true
      },

      deleteItemConfirmSpouse () {
        axios.get(process.env.VUE_APP_API_URL + 'employees/staffspousedel?spousename=' + this.editedItemSpouse.spousename + '&idemp=' + this.mode.idEmp, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
            })
            .then((response) => {
                console.log(response.data);
                // this.spouse.splice(this.editedIndexSpouse, 1)
                bus.$emit('changeIt', 'loadProfile');
                this.closeDeleteSpouse();
                
            })
            .catch(error => {
                console.log(error);
        })
        
      },

      closeSpouse () {
        this.dialogSpouse = false
        this.$nextTick(() => {
          this.editedItemSpouse = Object.assign({}, this.defaultItemSpouse)
          this.editedIndexSpouse = -1
        })
      },

      closeDeleteSpouse () {
        this.dialogDeleteSpouse = false
        this.$nextTick(() => {
          this.editedItemSpouse = Object.assign({}, this.defaultItemSpouse)
          this.editedIndexSpouse = -1
        })
      },

      saveSpouse () {
        if (this.editedIndexSpouse > -1) {
            // Object.assign(this.spouse[this.editedIndexSpouse], this.editedItemSpouse);
            axios.get(process.env.VUE_APP_API_URL + 'employees/staffspouseedit?id=' + this.editedItemSpouse.id + '&newname=' + this.editedItemSpouse.spousename + '&idemp=' + this.mode.idEmp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    console.log(response.data);
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    console.log(error);
            })
        } else {
            // this.spouse.push(this.editedItemSpouse);
            axios.get(process.env.VUE_APP_API_URL + 'employees/staffspouseadd?spousename=' + this.editedItemSpouse.spousename + '&idemp=' + this.mode.idEmp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    // this.spouse = response.data;
                    console.log(response.data);
                    // this.spouse.push(this.editedItemSpouse);
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    console.log(error);
            })
          
        }
        this.closeSpouse()
      },
    
      //child  
      editItemChild (item) {
        this.editedIndexChild = this.child.indexOf(item)
        this.editedItemChild = Object.assign({}, item)
        this.dialogChild = true
      },

      deleteItemChild (item) {
        this.editedIndexChild = this.child.indexOf(item)
        this.editedItemChild = Object.assign({}, item)
        this.dialogDeleteChild = true
      },

      deleteItemConfirmChild () {
        axios.get(process.env.VUE_APP_API_URL + 'employees/staffkiddel?kidsname=' + this.editedItemChild.childrenname + '&idemp=' + this.mode.idEmp, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
            })
            .then((response) => {
                console.log(response.data);
                // this.child.splice(this.editedIndexChild, 1)
                bus.$emit('changeIt', 'loadProfile');
                this.closeDeleteChild()
            })
            .catch(error => {
                console.log(error);
        })
        
      },

      closeChild () {
        this.dialogChild = false
        this.$nextTick(() => {
          this.editedItemChild = Object.assign({}, this.defaultItemChild)
          this.editedIndexChild = -1
        })
      },

      closeDeleteChild () {
        this.dialogDeleteChild = false
        this.$nextTick(() => {
          this.editedItemChild = Object.assign({}, this.defaultItemChild)
          this.editedIndexChild = -1
        })
      },

      saveChild () {
        if (this.editedIndexChild > -1) {
            // Object.assign(this.child[this.editedIndexChild], this.editedItemChild)
            axios.get(process.env.VUE_APP_API_URL + 'employees/staffkidedit?id=' + this.editedItemChild.id + '&newname=' + this.editedItemChild.childrenname + '&idemp=' + this.mode.idEmp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    console.log(response.data);
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    console.log(error);
            })
        } else {
            // this.child.push(this.editedItemChild);
            axios.get(process.env.VUE_APP_API_URL + 'employees/staffkidadd?kidsname=' + this.editedItemChild.childrenname + '&idemp=' + this.mode.idEmp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    console.log(response.data);
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    console.log(error);
            })
        }
        this.closeChild()
      },

      updatePersonalInfo(){
        //   console.log(this.personalInfo);
          const payload = {
            idEmp: this.mode.idEmp,
            idState: this.personalInfo.stateid,
            name: this.personalInfo.name,
            noIc: this.personalInfo.ic,
            gender: this.personalInfo.gender,
            address: this.personalInfo.address,
            postalCode: this.personalInfo.postalcode,
            city: this.personalInfo.city,
            phone: this.personalInfo.hp,
            homePhone: this.personalInfo.homeno,
            email1: this.personalInfo.email1,
            email2: this.personalInfo.email2,
            dateBirth: this.personalInfo.datebirth,
            placeBirth: this.personalInfo.placeofbirth,
            maritalStatus: this.personalInfo.maritalstatus,
            drivingb2: this.personalInfo.drivinglicense_b2 == 'b2' ? 'Yes' : 'No',
            drivingd: this.personalInfo.drivinglicense_d  == 'd' ? 'Yes' : 'No',
            drivingda: this.personalInfo.drivinglicense_da  == 'da' ? 'Yes' : 'No'
        };
        // console.log(payload);
        axios.put(process.env.VUE_APP_API_URL + 'employees/edit2', payload, {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.token,
              }
          })
          .then((response) => {
                // console.log(response);
                this.snackbar = true;
                this.messagetext = response.data.message != 'User edited' ? response.data.message : 'Personal Info successfully updated!';
                this.messagecolor = 'success';
                bus.$emit('changeIt', 'loadProfile');
                this.$emit('updatedData','Yes');
          })
          .catch(error => {
              console.log(error);
          })
      },

      // Text Field must in number type
      isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
      },

      checkLength(valueNumber){ //eslint-disable-line

        //   console.log(valueNumber.length);

        //   if (valueNumber.length >= 10) {
        //       this.disableBtnUpdate = false;
        //   }
        //   else{
        //       this.disableBtnUpdate = true;
        //   }
      },

    },

    mounted() {
    //   this.getPersonalInfo();
    //   this.getSpouseList();
    //   this.getChildList();
      this.getState();

    //   console.log(this.personalInfo.hp.length);

    //   if (this.personalInfo.hp.length >= 10) {
    //       this.disableBtnUpdate = false;
    //   }
    //   else{
    //       this.disableBtnUpdate = true;
    //   }

    //   else if (localStorage.disableBtnUpdate == false) {
    //       this.disableBtnUpdate = false;
    //   }
    //   else{
    //       this.disableBtnUpdate = true;
    //   }
    }
}
</script>