<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-3 green"
        icon
        @click="exportExcel"
        >
          <v-icon class="white--text">mdi-microsoft-excel</v-icon>
      </v-btn>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        outlined
        dense
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headersViewList"
      :items="filteredEmployee"
      :search="search"
    >

      <template v-slot:[`header.company`]="{ header }">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small v-if="companyFilter == ''">
                  mdi-filter
              </v-icon>
              <v-icon small v-else>
                  mdi-filter-check
              </v-icon>
              </v-btn>
          </template>
          <div style="background-color: white; width: 280px" class="pa-2">
              <v-select
              v-model="companyFilter"
              :items="companyList"
              label="Company Name"
              dense
              outlined
              hide-details="auto"
              ></v-select>
              <v-btn
              @click="companyFilter = ''"
              small
              text
              color="primary"
              class="mt-2"
              >Clear</v-btn>
          </div>
          </v-menu>
      </template>
      <template v-slot:[`header.grade`]="{ header }">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small v-if="corporateFilter == ''">
                  mdi-filter
              </v-icon>
              <v-icon small v-else>
                  mdi-filter-check
              </v-icon>
              </v-btn>
          </template>
          <div style="background-color: white; width: 280px" class="pa-2">
              <v-select
              v-model="corporateFilter"
              :items="corporateList"
              label="Corporate Title"
              dense
              outlined
              hide-details="auto"
              ></v-select>
              <v-btn
              @click="corporateFilter = ''"
              small
              text
              color="primary"
              class="mt-2"
              >Clear</v-btn>
          </div>
          </v-menu>
      </template>
      
      <template v-slot:[`item.picture`]="props">
          <template>
              <v-avatar size="36px">
                <img
                  :src="props.item.picture"
                  :alt="props.item.name"
                  v-if="props.item.picture != null"
                  style="cursor: pointer"
                  @click="imageUserItem(props.item)"
                >
                <img
                  src="https://apis.spatialworks.com.my/publicdir/2022/01/20/noprofil.png"
                  style="cursor: pointer"
                  @click="imageUserItem(props.item)"
                  v-else
                >
              </v-avatar>
          </template>
      </template>
      <template v-slot:[`item.details`]="props">
          <template>
              <v-icon small class="mr-2 primary--text" @click="detailsUserItem(props.item)">mdi-file-document</v-icon>
          </template>
      </template>
      
      <template v-slot:[`item.update`]="props">
          <template>
              <v-icon small class="mr-2 green--text" @click="editUserItem(props.item.idemp)">mdi-pencil</v-icon>
          </template>
      </template>

    </v-data-table>

     <template>
        <v-dialog
        v-model="detailsDialog"
        max-width="600px"
        scrollable
        >
          <v-card>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Name:</v-list-item-title>
                  <v-list-item-subtitle>{{ detailsList.name }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
      </template>

      <template>
        <div class="text-center">
          <v-dialog
            v-model="editDialog"
            max-width="1000"
            scrollable
            
          >

            <v-card>
              <v-card-title class="justify-center title-bar-modul">
                Staff Profile
              </v-card-title>

              <v-card-text>
                <!-- Tab List-->
                <v-tabs
                  v-model="tabs"
                  show-arrows="mobile"
                >

                  <v-tab
                    v-for="item in tabsProfile"
                    :key="item.tab"
                    class="tab-icon"
                  >
                    <v-icon>{{ item.icon }}</v-icon>
                    {{ item.tab }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabs">

                  <!-- tab personal info -->
                  <v-tab-item class="container-tab-modul">
                      <personal-info :mode="mode" :personalInfo="personalInfo" :spouse="spouse" :child='children' @updatedData="receivedChildDataUpdated($event)">
                          
                      </personal-info>
                  </v-tab-item>

                  <!-- tab employee info -->
                  <v-tab-item class="container-tab-modul">
                      <employee-info :mode="mode" :employeeInfo="employeeInfo" @updatedData="receivedChildDataUpdated($event)">

                      </employee-info>
                  </v-tab-item>

                  <!-- tab hr info -->
                  <v-tab-item class="container-tab-modul">
                      <hr-info :mode="mode" :financeInfo="financeInfo" :qualify="qualify" :certificate="certificate" @updatedData="receivedChildDataUpdated($event)">

                      </hr-info>
                  </v-tab-item>

                  <!-- tab emergency contact -->
                  <v-tab-item class="container-tab-modul">
                      <emergency-contact :mode="mode" :emergencyInfo="emergencyInfo" :emergency="emergency">

                      </emergency-contact>
                  </v-tab-item>

              </v-tabs-items>  
                
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  outlined
                  @click="closeEditDialog"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>

      <template>
        <div class="text-center">
            <v-dialog
            v-model="dialogSummaryProfile"
            width="344"
            height="500"
            >
                <v-card
                    class="mx-auto "
                    max-width="344"
                >
                    <!-- src="../assets/img/others/mobile-menu3.jpg" -->
                    <div
                    height="265px"
                    class="d-flex flex-column justify-center align-center pa-10 header-summaryCardProfile mb-5"
                    align="center"
                    >
                        <v-badge
                        bordered
                        overlap
                        offset-x="65"
                        offset-y="200"
                        class="custom-badge-btn"
                        >
                            <v-avatar size="250">
                                <v-img :src="detailsList.picture" v-if="detailsList.picture != null"></v-img>
                                <v-img src="https://apis.spatialworks.com.my/publicdir/2022/01/20/noprofil.png" v-else></v-img>
                            </v-avatar>
                        </v-badge>
                        
                    </div>
                    
                    <!-- align="center" class="d-flex flex-column justify-center align-center" -->


                    <v-card-text
                    v-text="detailsList.name.toLowerCase()"
                    class="text-capitalize subtitle-1 text-bold pb-0"
                    >
                    </v-card-text>

                    
                    <v-card-subtitle>
                        {{ detailsList.position != null && detailsList.position != '' ? detailsList.position : '' }}
                    </v-card-subtitle>

                    <v-card-text class="pb-5" style="display:flex;">
                        <v-container>
                            <v-row >
                                <p v-if="detailsList.phone != null && detailsList.phone != ''" class="mr-5 mb-1"><v-icon class="icon-text" small>mdi-phone</v-icon><span v-text="detailsList.phone"></span></p>
                                <p v-if="detailsList.phone == null || detailsList.phone == ''" class="mr-5 mb-1">
                                  <v-icon class="icon-text" small>mdi-phone</v-icon>
                                  <span>(no data)</span>
                                </p>
                            </v-row>
                            <v-row >
                                <p class="mr-5 mb-1"><v-icon class="icon-text" small>mdi-email</v-icon><span v-text="detailsList.email1 != null && detailsList.email1 != '' ? detailsList.email1 : detailsList.email2"></span></p>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                      

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            small
                            outlined
                            elevation="0"
                            color="red"
                            :disabled="detailsList.picture == null"
                            @click="confirmationDeleteProfileImage(detailsList)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="red--text">
                              mdi-image-off
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Clear/Delete Image</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            small
                            outlined
                            elevation="0"
                            color="primary"
                            class="ml-2"
                            :disabled="detailsList.picture == null"
                            @click="viewProfileImage(detailsList)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon class="primary--text">
                              mdi-message-image-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>View Image</span>
                      </v-tooltip>

                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary"
                        outlined
                        @click="dialogSummaryProfile = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>


            <!-- Warning Blank Field Form -->
            <template>
                <v-row justify="center">
                    <v-dialog
                    v-model="dialogConfirmDelete"
                    persistent
                    max-width="300"
                    >
                    <v-card>
                        <v-card-title class="text-h5 red--text">
                            <v-icon class="icon-text red--text">mdi-image-off</v-icon>
                            Delete
                        </v-card-title>
                        <v-card-text class="blackk--text">
                            Are you sure to clear this profile image?
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="text-bold primary--text"
                            text
                            @click="dialogConfirmDelete = false;dtProfileImgDelete = null;"
                        >
                            NO
                        </v-btn>
                        <v-btn
                            class="text-bold primary--text"
                            text
                            @click="deleteProfilePicture"
                        >
                            YES
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-row>
            </template>

            <!-- Snackbar for update message -->
            <template>
              <div class="text-center">
                  <v-snackbar
                  v-model="snackbar"
                  :timeout="timeout"
                  :color="messagecolor"
                  >
                  {{ messagetext }}

                  <template v-slot:action="{ attrs }">
                      <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                      >
                      Close
                      </v-btn>
                  </template>
                  </v-snackbar>
              </div>
            </template>
        </div>
        </template>
  </v-card>
    
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';
import Papa from "papaparse";

import personalInfo from '@/components/profile/PersonalInfo';
import employeeInfo from '@/components/profile/EmployeeInfo.vue';
import hrInfo from '@/components/profile/HRInfo.vue';
import emergencyContact from '@/components/profile/EmergencyContact.vue';

export default {
    components: {
        'personal-info': personalInfo,
        'employee-info': employeeInfo,
        'hr-info': hrInfo,
        'emergency-contact': emergencyContact,
    },

    data: () => ({
        // Snackbar message after update data
        snackbar: false,
        timeout: 3000,
        messagecolor: '',
        messagetext: '',

        dialogConfirmDelete: false,
        dtProfileImgDelete: null,


        dataExport: '',
        search: '',
        headersViewList: [
          { text: 'IMAGE', value: 'picture', sortable:false },
          {
            text: 'STAFF ID',
            align: 'start',
            sortable: false,
            value: 'idemp',
          },
          { text: 'NAME', value: 'name' },
          { text: 'PHONE', value: 'phone' },
          { text: 'COMPANY', value: 'company' },
          { text: 'STATUS', value: 'status' },
          { text: 'CORPORATE GRADE', value: 'grade' },
          { text: 'EXPERIENCE', value: 'experience' },
          // { text: 'DETAILS', value: 'details', sortable: false, align:'center' },
          { text: 'UPDATE', value: 'update', sortable: false, align:'center'},
        ],
        viewList: [],
        detailsList: {
            idEmp: '',
            idState: '',
            idUserAccess: '',
            name: '',
            noIc: '',
            password: '',
            address: '',
            postalCode: '',
            city: '',
            phone:  '',
            homePhone: '',
            email1: '',
            email2: '',
            dateBirth: '',
            placeBirth: '',
            maritalStatus: ''
        },
        detailsDialog: false,
        editDialog: false,
        dialogSummaryProfile: false,

        tabs: null,
        tabsProfile: [
            {tab:'Personal Info', icon:'mdi-account-details'},
            {tab:'Employee Info', icon:'mdi-account-tie'},
            {tab:'HR Info', icon:'mdi-briefcase-account-outline'},
            {tab:'Emergency Contact', icon:'mdi-card-account-phone-outline'}
        ],

        mode: {
            view: 'admin',
            idEmp: '',
            updated: ''
        },
        personalInfo: [],
        defaultPersonalinfo: {
          name: '',
          ic: '',
          address: '',
          postalcode: '',
          city: '',
          statename: '',
          hp: '',
          homeno: '',
          email1: '',
          email2: '',
          age: '',
          placeofbirth: '',
          maritalstatus: '',
          datebirth: '1995-04-10T00:00:00'
        },

        spouse: [],
        defaultSpouse: [{
            id: '',
            idemp: '',
            spousename: '' 
        }],

        children: [],
        defaultChildren: [{
            id: '',
            idemp: '',
            childrenname: '' 
        }],

        employeeInfo: [],
        defaultEmployeeInfo: {
            dateofemployment:'1970-01-01T00:00:00',
            personalgrade: null,
            personalgradeid: null,
            position: null,
            positionid: null,
            systemrole: null,
            systemroleid: null,
            company: null,
            companyid: null,
            staffnum: null,
            employtype: null,
            employtypeid: null,
            cardnum: null,
            supervisorname: null,
            supervisorid: null,
            superiorname: null,
            superiorid: null,
            totalannualleave: null,
            annualleaveused: null,
            annualleaveadjustment: null,
            totalmedicalleave: null,
            medicalleaveused: null,
            endofemployment: null
        },

        financeInfo: [],
        defaultFinanceInfo: {
          epfno:'',
          socsono:'',
          lhdnno:'',
          accbankno:'',
          namebank:'',
        },
        qualify: [],
        certificate: [],

        emergencyInfo: [
          {
            idemp: '',
            idstate:'',
            ecname: '',
            address: '',
            postalcode: '',
            city: '',
            homephone:'',
            officephone: '',
            phone: '',
            relationship: ''
          },
          {
            idemp: '',
            idstate:'',
            ecname: '',
            address: '',
            postalcode: '',
            city: '',
            homephone:'',
            officephone: '',
            phone: '',
            relationship: ''
          }
        ],
        defaultEmergencyInfo:  [
          {
            idemp: '',
            idstate:'',
            ecname: '',
            address: '',
            postalcode: '',
            city: '',
            homephone:'',
            officephone: '',
            phone: '',
            relationship: ''
          },
          {
            idemp: '',
            idstate:'',
            ecname: '',
            address: '',
            postalcode: '',
            city: '',
            homephone:'',
            officephone: '',
            phone: '',
            relationship: ''
          }
        ],
        emergency: null,

        companyList: [],
        corporateList: [],
        companyFilter: '',
        corporateFilter: '',
        
    }),
    computed: {
      filteredEmployee() {
      
        let conditions = [];
        
        if (this.companyFilter) {
            conditions.push(this.filterCompany);
        }
        if (this.corporateFilter) {
            conditions.push(this.filterCorporate);
        }

        if (conditions.length > 0) {
          return this.viewList.filter((list) => {
            return conditions.every((condition) => {
                return condition(list);
            })
          })
        }
        return this.viewList;
      }
    },

    methods: {
        getAllStaff(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.viewList = response.data;
                // console.log(this.viewList);
            })
            .catch(error => {
                console.log(error);
            })
        },

        getDropdownList(){

            axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {                
                for(let i = 0; i < response.data.company.length; i++){
                    this.companyList.push(response.data.company[i].name);
                }

                for(let i = 0; i < response.data.personalgrade.length; i++){
                    this.corporateList.push(response.data.personalgrade[i].name);
                }

            })
            .catch(error => {
                console.log(error);
            })
        },

        detailsUserItem(item) {
            this.detailsIndex = this.viewList.indexOf(item)
            this.detailsList = Object.assign({}, item)
            this.detailsDialog = true
        },

        imageUserItem(item) {
            this.detailsIndex = this.viewList.indexOf(item)
            this.detailsList = Object.assign({}, item)
            this.dialogSummaryProfile = true
        },
        close () {
            this.detailsDialog = false
        },

        editUserItem(item) {
            this.mode.idEmp = item;
            this.getAllInfoStaff ();
            this.editDialog = true;
        },

        closeEditDialog () {
            this.mode.idEmp = '';
            this.editDialog = false;
            this.tabs = 0;

            this.mode.updated == '' ? '' : this.getAllStaff();
        },

        getAllInfoStaff (){

          let value; 

          this.emergencyInfo = [
            {
              idemp: '',
              idstate:'',
              ecname: '',
              address: '',
              postalcode: '',
              city: '',
              homephone:'',
              officephone: '',
              phone: '',
              relationship: ''
            },
            {
              idemp: '',
              idstate:'',
              ecname: '',
              address: '',
              postalcode: '',
              city: '',
              homephone:'',
              officephone: '',
              phone: '',
              relationship: ''
            }
          ]
            
          this.mode.view == 'user' ? value = this.$store.getters.user.idEmp : value = this.mode.idEmp;

          axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=1&id=' + value, {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.token,
              }
          })
          .then((response) => {
            // Tab 2 - Personal Info
            // response.data.personalinfo.datebirth == null ? this.personalInfo = this.defaultPersonalinfo : this.personalInfo = response.data.personalinfo;
            this.personalInfo = response.data.personalinfo;
            this.personalInfo.datebirth == null ? '' : this.personalInfo.datebirth = response.data.personalinfo.datebirth.split('T')[0];

            this.personalInfo.drivinglicense_b2 = (response.data.drivinglicense != null) ? response.data.drivinglicense.b2 == null ? null : 'b2' : null;
            this.personalInfo.drivinglicense_d = (response.data.drivinglicense != null) ? response.data.drivinglicense.d == null ? null : 'd' : null;
            this.personalInfo.drivinglicense_da = (response.data.drivinglicense != null) ? response.data.drivinglicense.da == null ? null : 'da' : null;
            this.personalInfo['empmartial'] = this.personalInfo.maritalstatus;

            this.spouse = response.data.spouse;
            response.data.spouse.length > 0 ? this.spouse = response.data.spouse : this.spouse = [];
            
            this.children = response.data.children;
            response.data.children.length > 0 ? this.children = response.data.children : this.children = [];

            // console.log(this.personalInfo);


            // Tab 3 - Employee Info
            // response.data.employeeinfo.dateofemployment == null ? this.employeeInfo = this.defaultEmployeeInfo : this.employeeInfo = response.data.employeeinfo;
            this.employeeInfo = response.data.employeeinfo;
            this.employeeInfo['dateofemployment'] == null ? '' : this.employeeInfo['dateofemployment'] = response.data.employeeinfo.dateofemployment.split('T')[0];
            this.employeeInfo['endofemployment'] == null ? '' : this.employeeInfo['endofemployment'] = response.data.employeeinfo.endofemployment.split('T')[0];
            this.employeeInfo['startcontractdt'] == null ? '' : this.employeeInfo['startcontractdt'] = response.data.employeeinfo.startcontractdt.split('T')[0];
            this.employeeInfo['endcontractdt'] == null ? '' : this.employeeInfo['endcontractdt'] = response.data.employeeinfo.endcontractdt.split('T')[0];
            
            this.employeeInfo['empstatus'] = this.employeeInfo.employtype;
            if(this.employeeInfo.kontraklist.length > 0){
              for(let i = 0; i < this.employeeInfo.kontraklist.length; i++){
                this.employeeInfo.kontraklist[i]['startdatecontract'] = this.employeeInfo.kontraklist[i]['startdatecontract'].split('T')[0]
                this.employeeInfo.kontraklist[i]['enddatecontract'] = this.employeeInfo.kontraklist[i]['enddatecontract'].split('T')[0]
              }
            }


            // Tab 4 - HR Info
            response.data.financeinfo == null ? this.financeInfo = this.defaultFinanceInfo : this.financeInfo = response.data.financeinfo;
            this.qualify = response.data.educationinfo;
            this.certificate = response.data.certifications;
            
            // Tab 5 - Emergency Contact
            // console.log("checkkkk",response.data.emergencycontacts);
            
            // response.data.emergencycontacts.length > 0 ? this.emergencyInfo = response.data.emergencycontacts : this.emergencyInfo = this.defaultEmergencyInfo;
            if( response.data.emergencycontacts.length > 0 ){
              for(let i in response.data.emergencycontacts){
                for(let x in this.emergencyInfo){
                  if(i == x){
                    this.emergencyInfo[x] = response.data.emergencycontacts[i]
                  }
                }
              }
            }
            response.data.emergencycontacts.length > 0 ? this.emergency = 'edit' : this.emergency = 'new';

          })
          .catch(error => {
              console.log(error);
          })
        },

        receivedChildDataUpdated(value){
          this.mode.updated = value;
        },

        filterCompany(item) {
          if (item.company != null){
            return item.company.includes(this.companyFilter);
          }
        },
        filterCorporate(item) {
          if (item.grade != null){
            return item.grade.includes(this.corporateFilter);
          }
        },

        exportExcel(){

            let keys = [
                "idemp",
                "name",
                "phone",
                "company",
                "status",
                "grade",
                "experience"
            ],
            resultAfterFilter = this.filteredEmployee.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

            let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");

            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'Staff List.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        confirmationDeleteProfileImage(data){

          this.dtProfileImgDelete = data;

          this.dialogConfirmDelete = true;

        },

        viewProfileImage(data){
          window.open(data.picture, "_blank");
        },

        deleteProfilePicture(){
          // console.log(data);
          axios.get(process.env.VUE_APP_API_URL + 'employees/delpic?idemp=' + this.dtProfileImgDelete.idemp, {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.token,
              }
          })
          .then((response) => {
            console.log(response.data);
            this.dialogSummaryProfile = false;
            this.dialogConfirmDelete = false;

            this.snackbar = true;
            this.messagetext = 'Profile picture is successfully delete!';
            this.messagecolor = 'success';
            
            // this.reloadPage();
            this.getAllStaff();
            this.getDropdownList();
            this.filteredEmployee();

            
          })
          .catch(error => {
              console.log(error);
              // this.dialogSummaryProfile = false;
              // this.dialogConfirmDelete = false;
              // this.snackbar = true;
              // this.messagetext = 'Something went wrong. Please try again.';
              // this.messagecolor = 'error';
          })
        }




    },
    mounted() {
        this.getAllStaff();
        this.getDropdownList();

        // if (this.personalInfo.hp.length >= 10) {
        //     this.disableBtnUpdate = false;
        // }
        // else{
        //     this.disableBtnUpdate = true;
        // }
        
    },

    created() {
        bus.$on('changeIt', (data) => {
            if (data == 'loadProfile') {
                this.getAllInfoStaff();
            }
            if (data == 'changeId') {
                this.closeEditDialog();
            }
        });
    }
}
</script>