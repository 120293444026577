<template>
    <v-container>

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-account</v-icon>
            Profile
        </v-card-title>

        <!-- Tab List-->
        <v-tabs
          v-model="tabs"
          show-arrows="mobile"
        >

          <v-tab
            v-for="item in tabsProfile"
            :key="item.tab"
            class="tab-icon"
          >
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
            
            <!-- tab my profile -->
            <v-tab-item  class="container-tab-modul">
                <my-profile>

                </my-profile>
            </v-tab-item>

            <!-- tab personal info -->
            <v-tab-item class="container-tab-modul">
                <personal-info :mode="mode" :personalInfo="personalInfo" :spouse="spouse" :child='children'>
                    
                </personal-info>
            </v-tab-item>

            <!-- tab employee info -->
            <v-tab-item class="container-tab-modul">
                <employee-info :mode="mode" :employeeInfo="employeeInfo">

                </employee-info>
            </v-tab-item>

            <!-- tab hr info -->
            <v-tab-item class="container-tab-modul">
                <hr-info :mode="mode" :financeInfo="financeInfo" :qualify="qualify" :certificate="certificate">

                </hr-info>
            </v-tab-item>

            <!-- tab emergency contact -->
            <v-tab-item class="container-tab-modul">
                <emergency-contact :mode="mode" :emergencyInfo="emergencyInfo" :emergency="emergency">

                </emergency-contact>
            </v-tab-item>

        </v-tabs-items>

    </v-container>
</template>

<script>

import { bus } from '@/main';
import axios from 'axios';
import myProfile from '@/components/profile/MyProfile';
import personalInfo from '@/components/profile/PersonalInfo';
import employeeInfo from '@/components/profile/EmployeeInfo.vue';
import hrInfo from '@/components/profile/HRInfo.vue';
import emergencyContact from '@/components/profile/EmergencyContact.vue';


export default {
    
    components: {
        'my-profile': myProfile,
        'personal-info': personalInfo,
        'employee-info': employeeInfo,
        'hr-info': hrInfo,
        'emergency-contact': emergencyContact,
    },
    data: () => ({

        tabs: null,
        tabsProfile: [
            {tab:'My Profile', icon:'mdi-card-account-details-outline'},
            {tab:'Personal Info', icon:'mdi-account-details'},
            {tab:'Employee Info', icon:'mdi-account-tie'},
            {tab:'HR Info', icon:'mdi-briefcase-account-outline'},
            {tab:'Emergency Contact', icon:'mdi-card-account-phone-outline'}
        ],

        mode: {
            view: 'user',
            idEmp: ''
        },

        personalInfo: [],
        defaultPersonalinfo: {
          name: '',
          ic: '',
          address: '',
          postalcode: '',
          city: '',
          statename: '',
          hp: '',
          homeno: '',
          email1: '',
          email2: '',
          age: '',
          placeofbirth: '',
          maritalstatus: '',
          datebirth: '1995-04-10T00:00:00'
        },

        spouse: [],
        defaultSpouse: [{
            id: '',
            idemp: '',
            spousename: '' 
        }],

        children: [],
        defaultChildren: [{
            id: '',
            idemp: '',
            childrenname: '' 
        }],

        employeeInfo: [],
        defaultEmployeeInfo: {
            dateofemployment:'1970-01-01T00:00:00',
            personalgrade: null,
            personalgradeid: null,
            position: null,
            positionid: null,
            systemrole: null,
            systemroleid: null,
            company: null,
            companyid: null,
            staffnum: null,
            employtype: null,
            employtypeid: null,
            cardnum: null,
            supervisorname: null,
            supervisorid: null,
            superiorname: null,
            superiorid: null,
            totalannualleave: null,
            annualleaveused: null,
            annualleaveadjustment: null,
            totalmedicalleave: null,
            medicalleaveused: null,
            endofemployment: null
        },

        financeInfo: [],
        defaultFinanceInfo: {
          epfno:'',
          socsono:'',
          lhdnno:'',
          accbankno:'',
          namebank:'',
        },
        qualify: [],
        certificate: [],

        emergencyInfo: [
          {
            idemp: '',
            idstate:'',
            ecname: '',
            address: '',
            postalcode: '',
            city: '',
            homephone:'',
            officephone: '',
            phone: '',
            relationship: ''
          },
          {
            idemp: '',
            idstate:'',
            ecname: '',
            address: '',
            postalcode: '',
            city: '',
            homephone:'',
            officephone: '',
            phone: '',
            relationship: ''
          }
        ],
        defaultEmergencyInfo:  [
          {
            idemp: '',
            idstate:'',
            ecname: '',
            address: '',
            postalcode: '',
            city: '',
            homephone:'',
            officephone: '',
            phone: '',
            relationship: ''
          },
          {
            idemp: '',
            idstate:'',
            ecname: '',
            address: '',
            postalcode: '',
            city: '',
            homephone:'',
            officephone: '',
            phone: '',
            relationship: ''
          }
        ],
        emergency: '' 
    }),

    methods: {
        getAllInfoStaff (){

          axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=1&id=' + this.$store.getters.user.idEmp, {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.token,
              }
          })
          .then((response) => {
            this.mode.idEmp = this.$store.getters.user.idEmp;
            // Tab 2 - Personal Info
            // response.data.personalinfo.datebirth == null ? this.personalInfo = this.defaultPersonalinfo : this.personalInfo = response.data.personalinfo;
            this.personalInfo = response.data.personalinfo;
            this.personalInfo.datebirth == null ? '' : this.personalInfo.datebirth = response.data.personalinfo.datebirth.split('T')[0];
            
            this.personalInfo.drivinglicense_b2 = (response.data.drivinglicense != null) ? response.data.drivinglicense.b2 == null ? null : 'b2' : null;
            this.personalInfo.drivinglicense_d = (response.data.drivinglicense != null) ? response.data.drivinglicense.d == null ? null : 'd' : null;
            this.personalInfo.drivinglicense_da = (response.data.drivinglicense != null) ? response.data.drivinglicense.da == null ? null : 'da' : null;
            this.personalInfo['empmartial'] = this.personalInfo.maritalstatus;

            this.spouse = response.data.spouse;
            response.data.spouse.length > 0 ? this.spouse = response.data.spouse : this.spouse = [];
            
            this.children = response.data.children;
            response.data.children.length > 0 ? this.children = response.data.children : this.children = [];


            // Tab 3 - Employee Info
            // response.data.employeeinfo.dateofemployment == null ? this.employeeInfo = this.defaultEmployeeInfo : this.employeeInfo = response.data.employeeinfo;
            this.employeeInfo = response.data.employeeinfo;
            this.employeeInfo['dateofemployment'] == null ? '' : this.employeeInfo['dateofemployment'] = response.data.employeeinfo.dateofemployment.split('T')[0];
            this.employeeInfo['endofemployment'] == null ? '' : this.employeeInfo['endofemployment'] = response.data.employeeinfo.endofemployment.split('T')[0];
            this.employeeInfo['startcontractdt'] == null ? '' : this.employeeInfo['startcontractdt'] = response.data.employeeinfo.startcontractdt.split('T')[0];
            this.employeeInfo['endcontractdt'] == null ? '' : this.employeeInfo['endcontractdt'] = response.data.employeeinfo.endcontractdt.split('T')[0];

            this.employeeInfo['empstatus'] = this.employeeInfo.employtype;
            if(this.employeeInfo.kontraklist.length > 0){
              for(let i = 0; i < this.employeeInfo.kontraklist.length; i++){
                this.employeeInfo.kontraklist[i]['startdatecontract'] = this.employeeInfo.kontraklist[i]['startdatecontract'].split('T')[0]
                this.employeeInfo.kontraklist[i]['enddatecontract'] = this.employeeInfo.kontraklist[i]['enddatecontract'].split('T')[0]
              }
            }

            // Tab 4 - HR Info
            response.data.financeinfo == null ? this.financeInfo = this.defaultFinanceInfo : this.financeInfo = response.data.financeinfo;
            this.qualify = response.data.educationinfo;
            this.certificate = response.data.certifications;
            
            // Tab 5 - Emergency Contact
            // response.data.emergencycontacts.length > 0 ? this.emergencyInfo = response.data.emergencycontacts : this.emergencyInfo = this.defaultEmergencyInfo;
            if( response.data.emergencycontacts.length > 0 ){
              for(let i in response.data.emergencycontacts){
                for(let x in this.emergencyInfo){
                  if(i == x){
                    this.emergencyInfo[x] = response.data.emergencycontacts[i]
                  }
                }
              }
            }
            response.data.emergencycontacts.length > 0 ? this.emergency = 'edit' : this.emergency = 'new';

          })
          .catch(error => {
              console.log(error);
          })
        },
    },

    mounted() {
        this.getAllInfoStaff();
    },

    created() {
        bus.$on('changeIt', (data) => {
            if (data == 'loadProfile') {
                this.getAllInfoStaff();
            }
        });
    }



}
</script>

<style lang="scss" scoped>
@import '~scss/main';

 .container-modul{
    padding: 10px;
    // height: 570px;
    height: calc(100vh - 220px);
    overflow-y: auto;
}

</style>