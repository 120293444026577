import { bus } from '@/main';
import axios from 'axios';
 
export default {
    data() {
        return {
            
            totalPendingCar: null,
            pendingCar: [],
            countNotiCar: null,

            totalPendingLeave: null,
            pendingLeave: [],
            countNotiLeave: null,

            totalPendingMeetingRoom: null,
            pendingMeetingRoom: [],
            countNotiMeetingRoom: null,

            // globalListCar: [
            //      { id: 1, idApproval: 'FT159', label: "Perodua Bezza (Red - SSA2469A)", colorCheckbox: "primary", calendarColor: "ssa2469a", plate: "SSA 2469 A", company: "SPATIALWORKS SDN BHD", events: "dailyCar"},
            //      { id: 2, idApproval: 'FT159', label: "Perodua Bezza (Blue - SSA2369A)", colorCheckbox: "primary", calendarColor: "ssa2369a", plate: "SSA 2369 A", company: "SPATIALWORKS SDN BHD", events: "dailyCar"},
            //     { id: 3, idApproval: 'FT159', label: "Perodua Axia (Blue - VFP8523)", colorCheckbox: "primary", calendarColor: "vfp8523", plate: "VFP 8523", company: "SPATIALWORKS SDN BHD", events: "projectCar"},
            //     { id: 4, idApproval: 'FT159', label: "Ford Ranger (Blue - SS6955W)", colorCheckbox: "primary", calendarColor: "ss6955w", plate: "SS 6955 W", company: "SPATIALWORKS SDN BHD", events: "projectCar"},
            //     { id: 5, idApproval: 'FT023', label: "Isuzu DMax (Grey - BME8395)", colorCheckbox: "primary", calendarColor: "bme8395", plate: "BME 8395", company: "SCIENOTECH SDN BHD", events: "projectCar"},
            //     { id: 6, idApproval: 'FT159', label: "Perodua Axia (Silver - VP2514)", colorCheckbox: "primary", calendarColor: "vp2514", plate: "VP 2514", company: "SPATIALWORKS SDN BHD", events: "projectCar"},
            //     { id: 7, idApproval: 'CT2010', label: "Perodua Axia (White - DDA413)", colorCheckbox: "primary", calendarColor: "dda413", plate: "DDA 413", company: "SCIENOTECH SDN BHD", events: "projectCar"},
            //     { id: 8, idApproval: 'CT2010', label: "Perodua Axia (White - PMS8914)", colorCheckbox: "primary", calendarColor: "pms8914", plate: "PMS 8914", company: "SCIENOTECH SDN BHD", events: "projectCar"},
            // ],

            globalListCar: [
                { 
                    id: 1, 
                    label: "Perodua Bezza (Red - SSA2469A)", 
                    name: "Perodua Bezza", 
                    colorCar: "Red",
                    colorCheckbox: "primary", 
                    calendarColor: "ssa2469a", 
                    plate: "SSA 2469 A", 
                    company: "SCIENOTECH SDN BHD", 
                    events: "dailyCar",
                    src: require('@/assets/img/officeCar/BezzaRed.jpg'),
                    idApproval: 'FT026',
                    userApproval: 'Norain Binti Abd Aziz',
                    userContact: '0194476047'
                },
                { 
                    id: 2, 
                    label: "Perodua Bezza (Blue - SSA2369A)", 
                    name: "Perodua Bezza", 
                    colorCar: "Blue",
                    colorCheckbox: "primary", 
                    calendarColor: "ssa2369a", 
                    plate: "SSA 2369 A", 
                    company: "SPATIALWORKS SDN BHD", 
                    events: "dailyCar",
                    src: require('@/assets/img/officeCar/BezzaBlue.jpg'),
                    idApproval: 'FT052',
                    userApproval: 'Nurunajmi Binti Ibrahim',
                    userContact: '0194476047'
                },
                { 
                    id: 3, 
                    label: "Perodua Axia (Blue - VFP8523)", 
                    name: "Perodua Axia", 
                    colorCar: "Blue",
                    colorCheckbox: "primary", 
                    calendarColor: "vfp8523",
                    plate: "VFP 8523", 
                    company: "SPATIALWORKS SDN BHD", 
                    events: "projectCar",
                    src: require('@/assets/img/officeCar/AxiaBlue.jpg'),
                    idApproval: 'FT159',
                    userApproval: 'Nurulfida Khuzaien Binti Ahmad',
                    userContact: '0126096004',
                    },
                { 
                    id: 4, 
                    label: "Ford Ranger (Blue - SS6955W)", 
                    name: "Ford Ranger", 
                    colorCar: "Blue",
                    colorCheckbox: "primary", 
                    calendarColor: "ss6955w", 
                    plate: "SS 6955 W", 
                    company: "SCIENTIFIC ENVIRONMENT SDN BHD", 
                    events: "projectCar",
                    src: require('@/assets/img/officeCar/RangerBlue.jpg'),
                    idApproval: 'FT138',
                    userApproval: 'Nur Liyana Binti Sulaiman',
                    userContact: '0134842942'
                    },
                { 
                    id: 5, 
                    label: "Isuzu DMax (Grey - BME8395)", 
                    name: "Isuzu DMax", 
                    colorCar: "Grey",
                    colorCheckbox: "primary", 
                    calendarColor: "bme8395", 
                    plate: "BME 8395", 
                    company: "SCIENOTECH SDN BHD", 
                    events: "projectCar",
                    src: require('@/assets/img/officeCar/IsuzuGrey.jpg'),
                    idApproval: 'FT138',
                    userApproval: 'Nur Liyana Binti Sulaiman',
                    userContact: '0134842942'
                },
                { 
                    id: 6, 
                    label: "Perodua Axia (Silver - VP2514)", 
                    name: "Perodua Axia", 
                    colorCar: "Silver",
                    colorCheckbox: "primary",
                    calendarColor: "vp2514", 
                    plate: "VP 2514", 
                    company: "SCIENOTECH SDN BHD", 
                    events: "projectCar",
                    src: require('@/assets/img/officeCar/AxiaSilver.jpg'),
                    idApproval: 'FT159',
                    userApproval: 'Nurulfida Khuzaien Binti Ahmad',
                    userContact: '0126096004'
                },
                { 
                    id: 7, 
                    label: "Perodua Axia (White - DDA413)",
                    name: "Perodua Axia",  
                    colorCar: "White",
                    colorCheckbox: "primary", 
                    calendarColor: "dda413", 
                    plate: "DDA 413", 
                    company: "SCIENOTECH SDN BHD", 
                    events: "projectCar",
                    src: require('@/assets/img/officeCar/AxiaWhite1.jpg'),
                    idApproval: 'FT138',
                    userApproval: 'Nur Liyana Binti Sulaiman',
                    userContact: '0134842942'
                },
                { 
                    id: 8, 
                    label: "Perodua Axia (White - PMS8914)", 
                    name: "Perodua Axia", 
                    colorCar: "White",
                    colorCheckbox: "primary", 
                    calendarColor: "pms8914", 
                    plate: "PMS 8914", 
                    company: "SCIENOTECH SDN BHD", 
                    events: "projectCar",
                    src: require('@/assets/img/officeCar/AxiaWhite2.jpg'),
                    idApproval: 'CT2010',
                    userApproval: 'Nur Syafiqah Aina Binti Mohd Zukeri',
                    userContact: '0135831937'
                },
                { 
                    id: 9, 
                    label: "Isuzu DMax (Silver - SB5574G)", 
                    name: "Isuzu DMax", 
                    colorCar: "Silver",
                    colorCheckbox: "primary", 
                    calendarColor: "sb5574b", 
                    plate: "SB 5574 G", 
                    company: "SCIENOTECH SDN BHD", 
                    events: "projectCar",
                    src: require('@/assets/img/officeCar/IsuzuSilver.jpg'),
                    idApproval: 'FT138',
                    userApproval: 'Nur Liyana Binti Sulaiman',
                    userContact: '0134842942'
                },
            ],

            userDahUpdate: null,

            disableBtnUpdate: true,
        
        }
    },
    methods: {

        openProfileImage(){
            bus.$emit('changeIt', 'openDP');
        },

        reloadPage(){
            window.location.reload();
        },

        goToPage(url){
            this.$router.push({ path: url }).catch(()=>{});
            
            window.focusNotify = 0;
        },
 
        // Function for standardize (ARIP)
        convertDateTime(datadt){
            
            if(datadt != '' && datadt != null) {
                let [date, time] = datadt.split('T');

                // First Convert Date
                var dd = date.split("-")[2];
                var mm = date.split("-")[1];
                var yyyy = date.split("-")[0];
                let stringMM = [
                    {noMM: "01", strMM:"Jan"},
                    {noMM: "02", strMM:"Feb"},
                    {noMM: "03", strMM:"Mar"},
                    {noMM: "04", strMM:"Apr"},
                    {noMM: "05", strMM:"May"},
                    {noMM: "06", strMM:"Jun"},
                    {noMM: "07", strMM:"Jul"},
                    {noMM: "08", strMM:"Aug"},
                    {noMM: "09", strMM:"Sep"},
                    {noMM: "10", strMM:"Oct"},
                    {noMM: "11", strMM:"Nov"},
                    {noMM: "12", strMM:"Dec"},
                ]
                for(let i in stringMM){
                    if (stringMM[i].noMM == mm) {
                        mm = stringMM[i].strMM;
                    }
                }
                date = dd + " " + mm + " " + yyyy;

                // Convert Time to 12 hours
                var H = +time.substr(0, 2);
                var h = H % 12 || 12;
                var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
                
                datadt = date + ", " + time;
                return datadt;
            } else {
                return datadt = null;
            }
            

        },

        convertSortDate(dataD) { //NOTE: parameter 'dataD' must be in 17 Jan 2022 format

            // dataD = "10 Mar 2022, 11:44:02 AM";

            if(dataD == null || dataD == '') {
                return null
            } else {

                let dt = dataD.split(",")[0];
                let time = dataD.split(", ")[1];

                // Convert Date first
                var yyyy = dt.split(" ")[2];
                var mm = dt.split(" ")[1];
                var dd = dt.split(" ")[0];
                let stringMM = [
                    {noMM: "01", strMM:"Jan"},
                    {noMM: "02", strMM:"Feb"},
                    {noMM: "03", strMM:"Mar"},
                    {noMM: "04", strMM:"Apr"},
                    {noMM: "05", strMM:"May"},
                    {noMM: "06", strMM:"Jun"},
                    {noMM: "07", strMM:"Jul"},
                    {noMM: "08", strMM:"Aug"},
                    {noMM: "09", strMM:"Sep"},
                    {noMM: "10", strMM:"Oct"},
                    {noMM: "11", strMM:"Nov"},
                    {noMM: "12", strMM:"Dec"},
                ]
                for(let i in stringMM){
                    if (stringMM[i].strMM == mm) {
                        mm = stringMM[i].noMM;
                    }
                }
                dt = yyyy + "-" + mm + "-" + dd;

                // Convert Time
                const [timeNew, modifier] = time.split(' ');
                let [hours, minutes, seconds] = timeNew.split(':');
                if (hours === '12') {hours = '00';}
                if (modifier === 'PM') {hours = parseInt(hours, 10) + 12;}
                time = hours + ":" + minutes + ":" + seconds

                dataD = dt + "T" + time;
                return dataD; 
            }
        },


        convertTo12Hours(time) {   //NOTE: parameter 'time' must be in 00:00:00 format
            var H = +time.substr(0, 2);
            var h = H % 12 || 12;
            var ampm = (H < 12 || H === 24) ? "AM" : "PM";
            time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
            return time; //This will return to 12:00:00 AM
        },
        convertTo24Hours(time12h) {   //NOTE: parameter 'time12h' must be in 12:00:00 AM format
            const [time, modifier] = time12h.split(' ');
            let [hours, minutes, seconds] = time.split(':');
            if (hours === '12') {hours = '00';}
            if (modifier === 'PM') {hours = parseInt(hours, 10) + 12;}
            time12h = hours + ":" + minutes + ":" + seconds
            return time12h; //This will return to 00:00:00
        },
        convertToDDMMYYYY(date){   //NOTE: parameter 'date' must be in YYYY-MM-DD format
            var dd = date.split("-")[2];
            var mm = date.split("-")[1];
            var yyyy = date.split("-")[0];
            date = dd + "/" + mm + "/" + yyyy;
            return date;  //This will return to 17/01/2022
        },
        
        convertDateString(dataD){   //NOTE: parameter 'dataD' must be in YYYY-MM-DDT or YYYY-MM-DDT00:00:00
            // console.log(dataD);
            if(dataD == null || dataD == '') {
                return null
            } else {

                let stringMM = [
                    {noMM: "01", strMM:"Jan"},
                    {noMM: "02", strMM:"Feb"},
                    {noMM: "03", strMM:"Mar"},
                    {noMM: "04", strMM:"Apr"},
                    {noMM: "05", strMM:"May"},
                    {noMM: "06", strMM:"Jun"},
                    {noMM: "07", strMM:"Jul"},
                    {noMM: "08", strMM:"Aug"},
                    {noMM: "09", strMM:"Sep"},
                    {noMM: "10", strMM:"Oct"},
                    {noMM: "11", strMM:"Nov"},
                    {noMM: "12", strMM:"Dec"},
                ]

                if (dataD.includes('T')) {
                   dataD = dataD.slice(0, dataD.indexOf('T')) 
                }
                
                var dd = dataD.split("-")[2];
                var mm = dataD.split("-")[1];
                var yyyy = dataD.split("-")[0];
           
            
                for(let i in stringMM){
                    if (stringMM[i].noMM == mm) {
                        mm = stringMM[i].strMM;
                    }
                } 
                
                dataD = dd + " " + mm + " " + yyyy;
                return dataD;  //This will return to 17 Jan 2022
            }
        },

        convertToCalenderDate(dataD) { //NOTE: parameter 'dataD' must be in 17 Jan 2022 format
            if(dataD == null || dataD == '') {
                return null
            } else {
                var yyyy = dataD.split(" ")[2];
                var mm = dataD.split(" ")[1];
                var dd = dataD.split(" ")[0];
                let stringMM = [
                    {noMM: "01", strMM:"Jan"},
                    {noMM: "02", strMM:"Feb"},
                    {noMM: "03", strMM:"Mar"},
                    {noMM: "04", strMM:"Apr"},
                    {noMM: "05", strMM:"May"},
                    {noMM: "06", strMM:"Jun"},
                    {noMM: "07", strMM:"Jul"},
                    {noMM: "08", strMM:"Aug"},
                    {noMM: "09", strMM:"Sep"},
                    {noMM: "10", strMM:"Oct"},
                    {noMM: "11", strMM:"Nov"},
                    {noMM: "12", strMM:"Dec"},
                ]
                for(let i in stringMM){
                    if (stringMM[i].strMM == mm) {
                        mm = stringMM[i].noMM;
                    }
                }
                dataD = yyyy + "-" + mm + "-" + dd;
                return dataD; //This will return to YYYY-MM-DD
            }
        },


        convertToDBDate(date){   //NOTE: parameter 'date' must be in DD/MM/YYYY format
            var dd = date.split("/")[0];
            var mm = date.split("/")[1];
            var yyyy = date.split("/")[2];
            date = yyyy + "-" + mm + "-" + dd;
            return date;  //This will return to 2022-01-17
        },

        // Used to display today date with full string of month
        todayFullDate(today){

            if(today == null || today == '') {
                return null
            } else {

                let stringMM = [
                    {noMM: "01", strMM:"January"},
                    {noMM: "02", strMM:"February"},
                    {noMM: "03", strMM:"March"},
                    {noMM: "04", strMM:"April"},
                    {noMM: "05", strMM:"May"},
                    {noMM: "06", strMM:"June"},
                    {noMM: "07", strMM:"July"},
                    {noMM: "08", strMM:"August"},
                    {noMM: "09", strMM:"September"},
                    {noMM: "10", strMM:"October"},
                    {noMM: "11", strMM:"November"},
                    {noMM: "12", strMM:"December"},
                ]

                if (today.includes('T')) {
                   today = today.slice(0, today.indexOf('T')) 
                }
                
                var dd = today.split("-")[2];
                var mm = today.split("-")[1];
                var yyyy = today.split("-")[0];
           
            
                for(let i in stringMM){
                    if (stringMM[i].noMM == mm) {
                        mm = stringMM[i].strMM;
                    }
                } 
                
                today = dd + " " + mm + " " + yyyy;
                return today;
            }
        },

        // Display today in Hijri Date ==> Using function writeIslamicDate()
        gmod(n,m){
            return ((n%m)+m)%m;
        },

        hijriCalendar(adjust){
            const timeStamp = new Date().getTime();
            var yesterdayTimeStamp = timeStamp - 24*60*60*1000;
            var today = new Date(yesterdayTimeStamp);
            // var today = new Date();
            // console.log(today);
            if(adjust) {
                let adjustmili = 1000*60*60*24*adjust; 
                let todaymili = today.getTime()+adjustmili;
                today = new Date(todaymili);
            }
            let day = today.getDate();
            let month = today.getMonth();
            let year = today.getFullYear();
            let m = month+1;
            let y = year;
            if(m<3) {
                y -= 1;
                m += 12;
            }

            let a = Math.floor(y/100.);
            let b = 2-a+Math.floor(a/4.);
            if(y<1583) b = 0;
            if(y==1582) {
                if(m>10)  b = -10;
                if(m==10) {
                    b = 0;
                    if(day>4) b = -10;
                }
            }

            let jd = Math.floor(365.25*(y+4716))+Math.floor(30.6001*(m+1))+day+b-1524;

            b = 0;
            if(jd>2299160){
                a = Math.floor((jd-1867216.25)/36524.25);
                b = 1+a-Math.floor(a/4.);
            }
            let bb = jd+b+1524;
            let cc = Math.floor((bb-122.1)/365.25);
            let dd = Math.floor(365.25*cc);
            let ee = Math.floor((bb-dd)/30.6001);
            day =(bb-dd)-Math.floor(30.6001*ee);
            month = ee-1;
            if(ee>13) {
                cc += 1;
                month = ee-13;
            }
            year = cc-4716;
            
            let wd
            if(adjust) {
                wd = this.gmod(jd+1-adjust,7)+1;
            } else {
                wd = this.gmod(jd+1,7)+1;
            }

            let iyear = 10631./30.;
            let epochastro = 1948084;
            // let epochcivil = 1948085;

            let shift1 = 8.01/60.;
            
            let z = jd-epochastro;
            let cyc = Math.floor(z/10631.);
            z = z-10631*cyc;
            let j = Math.floor((z-shift1)/iyear);
            let iy = 30*cyc+j;
            z = z-Math.floor(j*iyear+shift1);
            let im = Math.floor((z+28.5001)/29.5);
            if(im==13) im = 12;
            let id = z-Math.floor(29.5001*im-29);

            var myRes = new Array(8);

            myRes[0] = day; //calculated day (CE)
            myRes[1] = month-1; //calculated month (CE)
            myRes[2] = year; //calculated year (CE)
            myRes[3] = jd-1; //julian day number
            myRes[4] = wd-1; //weekday number
            myRes[5] = id; //islamic date
            myRes[6] = im-1; //islamic month
            myRes[7] = iy; //islamic year

            return myRes;
        },

        writeIslamicDate(adjustment) {
            // console.log(adjustment);
            var wdNames = new Array("Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday");
            // var wdNames = new Array("Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday");
            var iMonthNames = new Array("Muharram","Safar","Rabiul Awwal","Rabiul Akhir",
            "Jamadil Awal","Jamadil Akhir","Rejab","Syaaban",
            "Ramadhan","Syawal","Zulkaedah","Zulhijjah");
            var iDate = this.hijriCalendar(adjustment);
            var outputIslamicDate = wdNames[iDate[4]] + ", " 
            + iDate[5] + " " + iMonthNames[iDate[6]] + " " + iDate[7] + " H";
            return outputIslamicDate;
        },

        findClosestPrevDate(arr,target){
            let targetDate = new Date(target);
            let previousDates = arr.filter(e => ( targetDate  - new Date(e)) > 0)
            let sortedPreviousDates =  previousDates.filter((a,b) => new Date(a) - new Date(b))
            return sortedPreviousDates[0] || null
        },

        capitalizeWords(str) {   //Can be used in any modul as long as string parameter not null
            if (str == null) {
                return str = "";   
            }
            else{
                return str.replace(
                    /\w\S*/g,
                    function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    }
                );
            }
        },
        
        goToPageNotify(){ //This function used for button notification only
            this.$router.push({ path: '/dashboard' }).catch(()=>{});
            window.focusNotify = 1;
            bus.$emit('changeIt', 'goToNotiList');
        },
        UserDisplay(str) {
            let string = str;
            let userName = "";

            

            if (string.split(" ").length > 2) {

                userName = string.split(" ")[0] + " " + string.split(" ")[1] + " " + string.split(" ")[2]
                // userName = string;

                if (userName.toLowerCase().includes('bin')
                || userName.toLowerCase().includes('binti')
                || userName.toLowerCase().includes('a/l')
                || userName.toLowerCase().includes('a/p')) {

                    if (userName.split(" ")[1].toLowerCase() == "bin"
                    || userName.split(" ")[1].toLowerCase() == "binti"
                    || userName.split(" ")[1].toLowerCase() == "a/l"
                    || userName.split(" ")[1].toLowerCase() == "a/p") {
                        userName = userName.split(" ")[0];
                        
                        return userName.replace(
                            /\w\S*/g,
                            function(txt) {
                            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                            }
                        );
                    }
                    else if (userName.split(" ")[2].toLowerCase() == "bin"
                    || userName.split(" ")[2].toLowerCase() == "binti"
                    || userName.split(" ")[2].toLowerCase() == "a/l"
                    || userName.split(" ")[2].toLowerCase() == "a/p") {
                        userName = userName.split(" ")[0] + " " + userName.split(" ")[1];
                        
                        return userName.replace(
                            /\w\S*/g,
                            function(txt) {
                            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                            }
                        );
                    }
                    else{
                        
                        return userName.replace(
                            /\w\S*/g,
                            function(txt) {
                            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                            }
                        );
                    }

                }
                else {
                    return this.capitalizeWords(userName);
                }

            }
            else{

                

                return str.replace(
                    /\w\S*/g,
                    function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    }
                ); 
            }

            
        }, //eslint-disable-line


        



        // NOTIFICATION SECTION
        loadNotifyLeave(){

            let dtToday = new Date(Date.now());
            let thisYear = dtToday.getFullYear()
            // console.log(thisYear);
            
            
            axios.get(process.env.VUE_APP_API_URL + 'cuti/mykulicutis?startdt='+thisYear+'-01-01&enddt='+thisYear+'-12-31', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                this.pendingLeave= [];
                this.countNotiLeave= null;
                this.totalPendingLeave= null;
                
                const data = response.data;
                // console.log(data);

                const empId = this.$store.getters.user.idEmp;
                // console.log(this.$store.getters.user);
                // console.log(empId);

                // let a= []
                // let b= []

                // let whichOneAreYou = '';

                for(let i = 0; i < data.length; i++){
                    if(empId == data[i].supervisorid){
                        // console.log(data[i]);
                        if (data[i].supervisorapproved == "PENDING" || data[i].supervisorapproved == null) {
                            this.pendingLeave.push(data[i]);
                            // a.push(data[i]);
                            this.countNotiLeave = this.pendingLeave.length;
                        }

                        // whichOneAreYou = 'sv';
                    } 
                    else if(empId == data[i].superiorid){
                        // console.log(data[i]);
                        if (data[i].superiorapproved == "PENDING" || data[i].superiorapproved == null) {
                            this.pendingLeave.push(data[i]);
                            // b.push(data[i]);
                            this.countNotiLeave = this.pendingLeave.length;
                        }
                        // whichOneAreYou = 'sr'
                    }
                }

                // console.log(a);
                // console.log(b);
                // console.log(whichOneAreYou);

                this.totalPendingLeave = this.countNotiLeave;
                
                
            })
            .catch(error => {
                // console.log(error);
                console.log(error.response);
            })
        },

        loadNotifyCar(){

            // this.loadNotifyLeave();

            const d = new Date();
            let thisYear = d.getFullYear();
            let prevD = d.setFullYear(d.getFullYear()-1);
            let prevYear = new Date(prevD).getFullYear();
            
            
            axios.get(process.env.VUE_APP_API_URL + 'car/allbookings?startdt='+prevYear+'-01-01T00:00:00&enddt='+thisYear+'-12-31T23:59:59&withrejected=1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                this.pendingCar= [];
                this.countNotiCar= null;
                this.totalPendingCar= null;
                
                let data = response.data;
                // console.log(data);

                for(let i in data){
                    if(data[i].status == "PENDING" || data[i].status == "pending" || data[i].status == "Pending"){

                        for(let x in this.globalListCar){

                            if (data[i].carType == this.globalListCar[x].label.toUpperCase()) {
                                if (this.globalListCar[x].idApproval == this.$store.getters.user.idEmp
                                || this.$store.getters.user.idUserAccess == 1) {
                                    this.pendingCar.push(data[i]);
                                    this.countNotiCar = this.pendingCar.length;
                                }
                            }

                        }
                        
                        
                    }
                }

                this.totalPendingCar = this.countNotiCar;
                
            })
            .catch(error => {
                // console.log(error);
                console.log(error.response);
            })
        },

        loadNotifyRoom(){

            // this.loadNotifyCar();
            
            axios.get(process.env.VUE_APP_API_URL + 'meetingroom/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                this.pendingMeetingRoom= [];
                this.countNotiCar= null;
                this.totalPendingMeetingRoom= null;
                
                let data = response.data;
                // console.log(data);

                for(let i in data){
                    if(data[i].refreshmentsstatus == "PENDING" || data[i].refreshmentsstatus == "pending" || data[i].refreshmentsstatus == "Pending"){
                        if(data[i].bookingstatus == "BOOKED"){
                            this.pendingMeetingRoom.push(data[i]);
                            this.countNotiCar = this.pendingMeetingRoom.length;
                        }
                    }
                }

                this.totalPendingMeetingRoom = this.countNotiCar;
                
            })
            .catch(error => {
                // console.log(error);
                console.log(error.response);
            })
        }

    },
    computed: {
        onlyAdmin() {
            return this.$store.getters.userRole === 'ADMIN' ? true : false
        },
        onlyNormalUser() {
            return this.$store.getters.userRole === 'NORMAL USER' ? true : false
        },
        onlyModerator() {
            return this.$store.getters.userRole === 'MODERATOR' ? true : false
        },
        onlyHR() {
            return this.$store.getters.userRole === 'HUMAN RESOURCE' ? true : false
        },
        onlyInfra() {
            return this.$store.getters.userRole === 'INFRASTRUCTURE' ? true : false
        },
        onlyCarManager() {
            return this.$store.getters.userRole === 'CAR MANAGER' ? true : false
        },
        onlyRoomManager() {
            return this.$store.getters.userRole === 'MEETING ROOM' ? true : false
        },
        adaKuli() {
            return this.$store.getters.user.kuli === true ? true : false
        },
        // Force User Update Profile Data
        // checkUserDegil(){

        //     axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
        //         headers: {
        //             'Authorization': 'Bearer ' + this.$store.getters.user.token,
        //         }
        //     })
        //     .then((response) => {
                
        //         let data = response.data;
        //         // console.log(data);

        //         for(let i in data){
                    
        //             if (data[i]['idEmp'] == this.$store.getters.user.idEmp) {

        //                 if (this.data[i]['phone'] == "" || this.data[i]['phone'] == null) {
        //                     return true;
        //                 }
        //                 else{
        //                     if (this.data[i]['picture'] == "" || this.data[i]['picture'] == null) {
        //                         return true;
        //                     }
        //                     else{
        //                         return false;
        //                     }
                            
        //                 }
        //             }

        //         }

                
                
        //     })
        //     .catch(error => {
        //         // console.log(error);
        //         console.log(error.response);
        //     })
            
        // },
        
    },
    mounted(){
        window.profileDetails = 0;
        // this.convertSortDate();
        // console.log(this.$store.getters.kuli);
        // this.loadNotifyLeave();
        // this.loadNotifyCar();
        // this.loadNotifyRoom();
    }
}