<template>
    <v-container> 

        <!-- Detail information -->
        <v-card>
            <v-card-text>
                <ul class="bullet-icon">
                    <li>
                    <v-icon small>mdi-shield-home</v-icon>
                    Only authorized visitor are allowed in the meeting room. Restricting unauthorized visitor is to provide safety 
                    and security of employees and the facilities at MNJ Group.
                    </li>

                    <li>
                    <v-icon small>mdi-handshake</v-icon>
                    If the meeting requires refreshment or technical support, kindly to submit the Booking Form (three) 3 days before the meeting.
                    </li>

                    <li>
                    <v-icon small>mdi-calendar-cursor</v-icon>
                    To make a reservation, click on the relevant date on Calendar date and fill out the Booking Form.
                    </li>

                    <li>
                    <v-icon small>mdi-chat-remove</v-icon>
                    To cancel the booking, click on your booking date and click cancel button on summary details table. <span class="text-bold">Please noted, cancel booking button will hidden once event start. Please cancel before event.</span> 
                    </li>

                    <li>
                    <v-icon small>mdi-chair-rolling</v-icon>
                    For info, MNJ01 Meeting Room  is main meeting room for MNJ Group. Staff are advised to book other available meeting rooms than MNJ01 to avoid it being used / booked by top management at any time. 
                    </li>
                </ul>
            </v-card-text>
        </v-card>

        <v-divider></v-divider>

        <!-- Vue Calendar -->
        <v-row class="fill-height">
            <v-col>
            <v-sheet class="calendarSizing" height="65">
                <v-toolbar
                flat
                >
                <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
                >
                    Today
                </v-btn>
                <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
                >
                    <v-icon small>
                    mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
                >
                    <v-icon small>
                    mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                bottom
                right
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                    >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>
                        mdi-menu-down
                        </v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                    <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
                <v-calendar
                :short-weekdays="false"
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="loadDataCalendar"
                ></v-calendar>
                <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
                max-width="480px"
                >
                    <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                    >
                        <v-toolbar
                        :color="selectedEvent.color"
                        dark
                        >
                        <v-btn
                        icon
                        v-if="me == selectedEvent.idemp"
                        @click="viewDetailsMyBooking(selectedEvent)"
                        >
                            <v-icon>mdi-clipboard-text-play-outline</v-icon>
                        </v-btn>
                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="selectedOpen = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-simple-table dense>
                                <template v-slot:default>
                                <tbody>
                                    <tr v-if="$vuetify.breakpoint.lgAndUp">
                                        <td colspan="2">

                                            <span v-for="(item, i) in listAllRooms" :key="i">
                                                <!-- <span v-if="item.id === selectedEvent.idmeetingroom"> -->
                                                    <v-img
                                                    v-if="item.id === selectedEvent.idmeetingroom"
                                                    class="my-3"
                                                    height="150"
                                                    width="350"
                                                    :src="item.src"
                                                    >
                                                    </v-img>
                                                <!-- </span> -->
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-calendar-clock</v-icon>
                                            Start Date
                                            <!-- <span v-html="selectedEvent.start + ' '"></span>
                                            <v-icon small>mdi-transfer-right</v-icon>
                                            <span v-html="' ' + selectedEvent.end"></span> -->
                                        </td>
                                        <td><span v-html="convertDateTime(selectedEvent.start)"></span></td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-calendar-clock</v-icon>
                                            End Date
                                        </td>
                                        <td><span v-html="convertDateTime(selectedEvent.end)"></span></td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-account</v-icon>
                                            User
                                        </td>
                                        <td>
                                            <span v-html="selectedEvent.userApply"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-cellphone-sound</v-icon>
                                            Contact
                                        </td>
                                        <td>
                                            <span v-if="selectedEvent.userContact != null" v-html="selectedEvent.userContact"></span>
                                            <span v-if="selectedEvent.userContact == null">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="dontBreak">
                                            <v-icon class="icon-text" small>mdi-card-text-outline</v-icon>
                                            Event
                                        </td>
                                        <td><span v-html="selectedEvent.event"></span></td>
                                    </tr>
                                    <tr v-if="me == selectedEvent.idemp && Date.parse(new Date(selectedEvent.start)) - Date.parse(new Date()) > 0">
                                        <td colspan="2" class="py-3">
                                            <v-btn
                                            small
                                            class="text-bold white--text"
                                            color="red"
                                            @click="warningDelete = true;"
                                            >
                                                Cancel Book
                                                <v-icon class="ml-2" small>mdi-chat-remove</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        <v-card-actions>
                        <!-- <v-btn
                        small
                        class="text-bold"
                        color="primary"
                        @click="selectedOpen = false"
                        >
                            Close
                        </v-btn> -->
                        
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-sheet>
            </v-col>
        </v-row>


        <!-- Label Coloring Car -->
        <div
        style="display:flex;"
        >
            <v-container>
                <v-row>
                    <span v-for="item in listColorRoom" :key="item.roomColor" style="margin-right:30px;">
                        <v-col>
                            <small>
                                <v-icon
                                class="icon-text"
                                v-bind:style="item.color">
                                    {{item.icon}}
                                </v-icon>
                                <span>{{item.room}}</span>
                            </small>
                        </v-col>
                    </span>
                </v-row>
            </v-container>
        </div>


        <!-- Popup Option Day Click Event -->
        <template>
            <v-dialog
            v-model="optionDayEvent"
            width="400"
            scrollable
            >
            <v-card>
                <v-card-title class="justify-center title-bar-modul">
                    <h4>Meeting Room Booking</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            align="center"
                        >
                            <v-btn
                                color="primary"
                                outlined
                                @click="zoomDaySelected"
                            >
                                View List
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            align="center"
                        >
                            <v-btn
                                color="primary"
                                outlined
                                @click="dialogAddDateMeetingRoomBook = true; optionDayEvent = false;"
                            >
                                Book Room
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-dialog>
        </template>

        <template>
            <v-dialog
            v-model="singleOptionDayEvent"
            width="380"
            scrollable
            >
            <v-card>
                <v-card-title class="justify-center title-bar-modul">
                    <h4>Meeting Room Booking</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col
                            align="center"
                        >
                            <v-btn
                                color="primary"
                                outlined
                                @click="zoomDaySelected"
                            >
                                View List
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-dialog>
        </template>

        <!-- Popup Booking Form -->
        <template>
            <v-dialog
            v-model="dialogAddDateMeetingRoomBook"
            persistent
            scrollable
            max-height="10px"
            max-width="600px"
            >
            <v-card>
                <v-card-title class="title-bar-modul">
                <span class="text-h5">Meeting Room Booking Form</span>
                </v-card-title>
                <v-card-text>
                <v-form ref="form">
                    <v-container>
                        <v-row>

                            <!-- Input Event Title -->
                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    outlined
                                    dense
                                    maxlength="50"
                                    label="Event Title"
                                    v-model="objectPostDataForm.eventTitle"
                                    :rules="rules.eventTitle"
                                    required
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Event Description -->
                            <v-col cols="12" class="py-0">
                                <v-textarea
                                    outlined
                                    dense
                                    rows="2"
                                    label="Event Description"
                                    maxlength="200"
                                    hint="*Insert remark"
                                    v-model="objectPostDataForm.eventDescription"
                                ></v-textarea>
                            </v-col>

                            <!-- Input Start Date -->
                            <v-col
                            cols="12"
                            sm="6"
                            class="py-0"
                            >
                                <v-menu
                                v-model="menuStartDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="startDate"
                                        label="Start Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="startDate"
                                    @change="menuStartDt = false;checkAvailableRoom(startTime,'dt');"
                                    :min="datePickerStartToday"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Input Start Time -->
                            <v-col
                            cols="12"
                            sm="6"
                            class="py-0"
                            >
                                <v-dialog
                                    ref="dialogStartTime"
                                    v-model="modalStartTime"
                                    :return-value.sync="startTime"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="startTime"
                                        label="Start Time"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="rules.startTime"
                                    ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    ampm-in-title
                                    v-if="modalStartTime"
                                    v-model="startTime"
                                    @change="checkAvailableRoom"
                                    full-width
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modalStartTime = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialogStartTime.save(startTime); checkAvailableRoom(startTime,'dt');"
                                    >
                                        OK
                                    </v-btn>
                                    </v-time-picker>
                                </v-dialog>   
                            </v-col>

                            <!-- Input End Date -->
                            <v-col
                            cols="12"
                            sm="6"
                            class="py-0"
                            >
                                <v-menu
                                v-model="menuEndtDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="endDate"
                                    label="End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="endDate"
                                    @change="menuEndtDt = false;checkAvailableRoom(startTime,'dt');"
                                    :min="datePickerStartToday"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Input End Time -->
                            <v-col
                            cols="12"
                            sm="6"
                            class="py-0"
                            >
                                <v-dialog
                                    ref="dialogEndTime"
                                    v-model="modalEndTime"
                                    :return-value.sync="endTime"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="endTime"
                                        label="End Time"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="rules.endTime"
                                    ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    ampm-in-title
                                    v-if="modalEndTime"
                                    v-model="endTime"
                                    @change="checkAvailableRoom"
                                    full-width
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modalEndTime = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialogEndTime.save(endTime);checkAvailableRoom(endTime,'dt');"
                                    >
                                        OK
                                    </v-btn>
                                    </v-time-picker>
                                </v-dialog>
                            </v-col >

                            <!-- Radio Button List Available Meeting Room -->
                            <v-col cols="12" class="py-0">
                                <v-card class="mb-5">
                                    <template>
                                    <v-container fluid>
                                        <v-icon class="text-icon">mdi-chair-rolling</v-icon> Select Available Meeting Room<span class="text-bold red--text"> *</span>
                                        <template>
                                            <span class="ml-1" :style="{visibility: visibleRoom ? 'visible' : 'hidden'}">
                                                <v-progress-circular
                                                size="20"
                                                indeterminate
                                                color="primary"
                                                ></v-progress-circular>
                                            </span>
                                        </template>  
                                        <v-radio-group row class="mt-2">
                                            <v-radio
                                            v-for="room in radioRoomType"
                                            :key="room.radios"
                                            :label="room.label"
                                            :color="room.colorCheckbox"
                                            :value="room.label"
                                            @change="chooseRoom(room)"
                                            small
                                            ></v-radio>
                                        </v-radio-group>

                                        <v-divider class="my-3"></v-divider>

                                        <v-card width="400px">
                                            <v-img width="400px" :src="imgRoomSelected">
                                            </v-img>
                                        </v-card>
                                    </v-container>
                                    </template>
                                </v-card>
                            </v-col>

                            <!-- Input Event Pax -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    prepend-inner-icon="mdi-account-group"
                                    label="Event Pax"
                                    hint="*Number of participants"
                                    @keypress="isNumber($event)"
                                    v-model="objectPostDataForm.eventPax"
                                    :rules="rules.eventPax"
                                    required
                                ></v-text-field>
                            </v-col>

                            <!-- Input/Checkbox Required Assistance -->
                            <v-col cols="12" class="pa-0">
                                <v-sheet class="px-5" :style="$vuetify.theme.dark == false? '' : 'background:#363636'">
                                    <v-switch
                                    class="mb-2"
                                    inset
                                    v-model="assistRequired"
                                    messages="(submission in 1-3 days before the meeting)"
                                    >
                                        <template v-slot:label>
                                            Required Assistance?
                                            <v-icon v-if="assistRequired == false" class="ml-2">mdi-projector</v-icon>
                                            <v-icon v-if="assistRequired == false">mdi-projector-screen-outline</v-icon>
                                            <v-icon v-if="assistRequired" class="ml-2" color="primary">mdi-projector</v-icon>
                                            <v-icon v-if="assistRequired" color="primary">mdi-projector-screen-outline</v-icon>
                                        </template>
                                    </v-switch>
                                </v-sheet>
                            </v-col>
                            <v-col cols="12" class="py-0"  v-if="assistRequired">
                                <v-textarea
                                    outlined
                                    dense
                                    rows="2"
                                    label="Technical assistance is required"
                                    hint="*Insert remark"
                                    v-model="objectPostDataForm.needSupport"
                                    :rules="rules.eventNeedSupportRemarks"
                                    maxlength="100"
                                ></v-textarea>
                            </v-col>

                            <!-- Input/Checkbox Required Refreshment -->
                            <v-col cols="12" class="pa-0">
                                <v-sheet class="px-5" :style="$vuetify.theme.dark == false? '' : 'background:#363636'">
                                    <v-switch
                                    class="mb-2"
                                    rows="2"
                                    inset
                                    v-model="assistRefreshment"
                                    messages="(submission in 1-3 days before the meeting)"
                                    >
                                        <template v-slot:label>
                                            Required Refreshment?
                                            <v-icon v-if="assistRefreshment == false" dense class="ml-2">mdi-coffee</v-icon>
                                            <v-icon v-if="assistRefreshment == false" dense class="ml-2">mdi-food</v-icon>
                                            <v-icon v-if="assistRefreshment" dense class="ml-2" color="primary">mdi-coffee</v-icon>
                                            <v-icon v-if="assistRefreshment" dense class="ml-2" color="primary">mdi-food</v-icon>
                                        </template>
                                    </v-switch>
                                </v-sheet>
                            </v-col>
                            <v-col cols="12" class="py-0" v-if="assistRefreshment">
                                <v-textarea
                                    outlined
                                    rows="2"
                                    dense
                                    label="Refreshment is required"
                                    hint="Food will only be served for meetings of more than 2 hours."
                                    v-model="objectPostDataForm.needRefresh"
                                    :rules="rules.eventRefreshRemarks"
                                    maxlength="100"
                                ></v-textarea>
                            </v-col>

                            <v-col>
                                <small class="text-bold blackk--text">
                                    **Name of applicant & applicant contact is automatically  display based on your profile data. Please make sure update your contact number at your profile.
                                    <v-icon small>
                                        mdi-account
                                    </v-icon>
                                </small>
                            </v-col>

                            <!-- Input/Display Applied User Name -->
                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    prepend-inner-icon="mdi-account"
                                    label="Name of Applicant"
                                    v-model="objectPostDataForm.userApply"
                                ></v-text-field>
                            </v-col>

                            <!-- Input/Display Applied User Contact-->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    prepend-inner-icon="mdi-phone"
                                    @keypress="isNumber($event)"
                                    label="Applicant Contact"
                                    v-model="objectPostDataForm.userContact"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small><span class="text-bold red--text">*</span>indicates required field</small>

                </v-form>
                
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="cancelFillBooking"
                >
                    Close
                </v-btn>
                <v-btn
                class="text-bold"
                color="primary"
                text
                :disabled="roomNotChoose"
                @click="submitRoomBooking"
                >
                    Submit
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>


        <!-- Cancel Booking Meeting Room -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="warningDelete"
                persistent
                max-width="300"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-delete-empty</v-icon>
                        Cancel Booking?
                    </v-card-title>
                    <v-card-text class="blackk--text">Are you sure want to cancel this booking?</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="warningDelete = false; confirmDelete = false;"
                    >
                        NO
                    </v-btn>
                    <v-btn
                        class="text-bold red--text"
                        text
                        @click="warningDelete = false; confirmDelete = true;cancelBooking(selectedEvent)"
                    >
                        YES
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>


        <!-- Success Booking & Cancel Meeting Room -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogSuccessMeetingRoomBook"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 success--text">
                        <v-icon class="icon-text success--text">mdi-check-circle</v-icon>
                        Success!
                    </v-card-title>
                    <v-card-text class="blackk--text">Meeting Room Booking is succesfully {{textSuccess}} in system.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogSuccessMeetingRoomBook = false; dialogAddDateMeetingRoomBook = false; "
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>


        <!-- Error -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogErrorMeetingRoomBooking"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Failed!
                    </v-card-title>
                    <v-card-text class="blackk--text">Something went wrong. Please try again.</v-card-text>
                    <br>
                    <v-card-text>
                        <small class="primary--text">
                            <span class="red--text text-bold">**</span> If the error still occurs, please contact HR.
                        </small> 
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogErrorMeetingRoomBooking = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>


        <!-- Warning Blank Field Form -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogWarning"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Warning!
                    </v-card-title>
                    <v-card-text class="blackk--text">
                        {{textWarning}}
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogWarning = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Popup Details -->
        <template>
            <v-dialog
            v-model="dialogDisplayDetails"
            persistent
            scrollable
            max-height="10px"
            max-width="700px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Meeting Room Details</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <tr
                        v-for="item in displayDetails"
                        :key="item.name"
                        >
                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                {{ item.key }}
                            </td>
                            
                            <td v-if="item.value != 'PENDING' && item.value != 'APPROVED' && item.value != 'REJECTED'">{{ item.value }}</td>
                            <td v-if="item.value == 'PENDING' || item.value == 'APPROVED' || item.value == 'REJECTED'">
                                <v-chip
                                dense
                                :class="controlColorFont(item.value)"
                                :color="controlBgColor(item.value)"
                                >
                                {{ item.value }}
                                </v-chip>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Reminder!! -->
        <template>
            <v-dialog
            v-model="dialogRemind"
            max-width="450"
            >
            <v-card>
                <v-card-title class="text-h5 primary--text">
                    <v-icon class="icon-text primary--text ml-3" >mdi-alert-circle</v-icon>
                    Reminder
                </v-card-title>
                <v-card-text class="blackk--text">
                    <!-- Reset password link has been sent to your emails. <br> Please check immediately within 15 mins. -->
                    <v-container>

                        <v-row>

                            <v-col cols="12">

                                <strong><v-icon dense class="red--text mr-2 mb-2">mdi-chair-rolling</v-icon>MNJ01 Meeting Room:</strong><br>
                                <div>
                                    For info, MNJ01 Meeting Room  is main meeting room for MNJ Group. Staff are advised to book other available meeting rooms than MNJ01 to avoid it being used / booked by top management at any time. 
                                </div>

                                <br>

                                <div>
                                    <strong><v-icon dense class="red--text mr-2 mb-2">mdi-chat-remove</v-icon>Cancel Booking:</strong><br>
                                    Cancel booking button will hidden once event start. Please cancel before event.
                                </div>

                                <br>

                                <div>
                                    <strong><v-icon dense class="red--text mr-2 mb-2">mdi-food</v-icon>Refreshment:</strong><br>
                                    Food will only be served for meetings of more than 2 hours.
                                </div>


                            </v-col>

                        </v-row>

                    </v-container>
                    

                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="text-bold primary--text"
                    text
                    @click="dialogRemind = false; "
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>



    </v-container>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    
    data: () => ({

        // Lain2
        dialogRemind: true,
        textSuccess: '',
        textWarning: '',

        // Calendar
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        dataDummy: [
            {
                name: "SPW 02 Meeting Room",
                start: "2022-03-26T09:00:00",
                end: "2022-03-26T10:00:00",
                color: "purple",
                timed: "true",
                userApply: "Zul Ariffudin",
                userContact: "013-1111111",
                room: "SIS 01 Meeting Room",
                detailEvent: "Meeting with HR",
                idUser: "COO-2"
            }
        ],
        roomColor: null,
        listColorRoom: [
            {id: 1, room: "MNJ 01 Meeting Room", color: "color: red;", icon: "mdi-chair-rolling"},
            {id: 2, room: "MNJ 02 Discussion Room", color: "color: #2196f3;", icon: "mdi-chair-rolling"},
            {id: 3, room: "SPW 01 Meeting Room", color: "color: #4caf50;", icon: "mdi-chair-rolling"},
            {id: 4, room: "SPW 02 Meeting Room", color: "color: purple;", icon: "mdi-chair-rolling"},
            {id: 5, room: "SCT 01 Meeting Room", color: "color: orange;", icon: "mdi-chair-rolling"},
            {id: 6, room: "SIS 01 Meeting Room", color: "color: grey darken-1;", icon: "mdi-chair-rolling"},
        ],
        // clickOnDay: false,
        dateSelected: '',



        // Object Data Form
        objectPostDataForm: {
            eventTitle: '',
            eventDescription: '',
            startDateTime: '',
            endDateTime: '',
            iDselectedRoom: null,
            labelColor: '',
            eventPax: '',
            needSupport: '',
            needRefresh: '',
            userApply: '',
            userContact: '',
        },


        // Store for combination date and time
        startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        startTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11,5),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        endTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11,5),

        // Set rule field is required
        rules: {
          eventTitle: [val => (val || '').length > 0 || 'This field is required'],
          startTime: [val => (val || '').length > 0 || 'Start time is required'],
          endTime: [val => (val || '').length > 0 || 'End Time is required'],
          eventPax: [val => (val || '').length > 0 || 'This field is required'],
          eventRefreshRemarks: [val => (val || '').length > 0 || 'This field is required'],
          eventNeedSupportRemarks: [val => (val || '').length > 0 || 'This field is required'],
        },


        // Engines/Dialog/Listing related to data form    
        menuStartDt: false,
        modalStartTime: false,
        menuEndtDt: false,
        datePickerStartToday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modalEndTime: false,
        radios: null,
        listRoom: [
            {id: 1, label: "MNJ 01 Meeting Room", colorCheckbox: "primary", calendarColor: "red"},
            {id: 2, label: "MNJ 02 Discussion Room", colorCheckbox: "primary", calendarColor: "blue"},
            {id: 3, label: "SPW 01 Meeting Room", colorCheckbox: "primary", calendarColor: "green"},
            {id: 4, label: "SPW 02 Meeting Room", colorCheckbox: "primary", calendarColor: "purple"},
            {id: 5, label: "SCT 01 Meeting Room", colorCheckbox: "primary", calendarColor: "orange"},
            {id: 6, label: "SIS 01 Meeting Room", colorCheckbox: "primary", calendarColor: "grey"},
        ],
        radioRoomType: [],
        visibleRoom: false,
        assistRequired: false,
        assistRefreshment: false,
        optionDayEvent: false,
        singleOptionDayEvent: false,
        daySelected: null,
        roomNotChoose: true,
        listAllRooms: [
            {
                id: 1,
                src: require('@/assets/img/meetingRooms/MNJ1.jpg'),
                name: "MNJ 01 Meeting Room"
            },
            {
                id: 2,
                src: require('@/assets/img/meetingRooms/MNJD.jpg'),
                name: "MNJ 02 Discussion Room"
            },
            {
                id: 3,
                src: require('@/assets/img/meetingRooms/SPW1.jpg'),
                name: "SPW 01 Meeting Room"
            },
            {
                id: 4,
                src: require('@/assets/img/meetingRooms/SPW2.jpg'),
                name: "SPW 02 Meeting Room"
            },
            {
                id: 5,
                src: require('@/assets/img/meetingRooms/SPW3.jpg'),
                name: "SCT 01 Meeting Room"
            },
            {
                id: 6,
                src: require('@/assets/img/meetingRooms/SIS1.jpg'),
                name: "SIS 01 Meeting Room"
            },
        ],
        imgRoomSelected: require('@/assets/img/officeCar/none.jpg'),


        // Display/View Data Details
        displayDetails: [
            {key: "Event Title", attribute: "event", value: "", icon: "mdi-pin-outline"},    
            {key: "Event Description", attribute: "description", value: "", icon: "mdi-calendar-text-outline"},    
            {key: "Date Applied", attribute: "dateapplied", value: "", icon: "mdi-calendar-cursor"},    
            {key: "Start Date Time", attribute: "start", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date Time", attribute: "end", value: "", icon: "mdi-calendar-end"},    
            {key: "Meeting Room", attribute: "idmeetingroom", value: "", icon: "mdi-chair-rolling"},    
            {key: "Event Pax", attribute: "eventpax", value: "", icon: "mdi-account-group"},    
            {key: "Assist Requirement", attribute: "Assistanceremarks", value: "", icon: "mdi-handshake-outline"},    
            {key: "Refreshment Requirement", attribute: "refreshmentsremarks", value: "", icon: "mdi-coffee"},
            {key: "Refreshment Status", attribute: "refreshmentsstatus", value: "", icon: "mdi-check-decagram-outline"},
        ],
        me: null,
        

        // Dialogs
        dialogAddDateMeetingRoomBook: false,
        warningDelete: false,
        dialogSuccessMeetingRoomBook: false,
        dialogErrorMeetingRoomBooking: false,
        dialogWarning: false,
        dialogDisplayDetails: false,


        textDescribe: '',
        firstDayOfWeek: 0,

        myName: null,
        myNumber: null,
    }),
    methods: {

        // General Functions
        load(){
            // console.log("load");
            this.me = this.$store.getters.user.idEmp;
            this.getMyDataProfile();
        },

        getMyDataProfile(){

            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                let data = response.data;
                for(let i in data){
                    if (data[i].idemp == this.$store.getters.user.idEmp) {
                        this.myName = data[i].name;
                        this.myNumber = data[i].phone;
                    }
                }
                
            })
            .catch(error => {
                console.log(error.response);
            })

        },


        // Calendar Section
        viewDay ({ date }) {

            // UNTUK SEMENTARA
            // this.loadDataCalendar();

            // console.log(Date.parse(date) + " is " + new Date(date));
            // console.log(Date.parse(new Date()) + " is " + new Date());

            // Set Input Date Time in Form Car Book as Current date & time
            this.daySelected = date;
            this.startDate = date;
            this.endDate = date;
            this.startTime = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5);
            this.endTime = this.startTime;
            this.loadListAvailableRoom(this.startDate,this.endDate,this.startTime,this.endTime);
            this.objectPostDataForm.userApply = this.myName;
            this.objectPostDataForm.userContact = this.myNumber;


            date = date + "T" + (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 8);

            let is_weekend =  "";
            if (new Date(date).getDay() == 6 || new Date(date).getDay() == 0) {
                is_weekend = true;
            }
            else{
                is_weekend = false;
            }

            // console.log(is_weekend);

            // If past day, jangan open popup booking
            if(Date.parse(new Date(date)) - Date.parse(new Date()) < 0)
            {
                // If weekday, open popup booking
                if (is_weekend == false || is_weekend == '') {
                    this.singleOptionDayEvent = true;
                }
            }
            else{
                // If weekday, open popup booking
                if (is_weekend == false || is_weekend == '') {
                    this.optionDayEvent = true;
                }
            }
        

            // Uncomment below code for zoom event day
            // this.focus = date
            // this.type = 'day'

        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                let myInterval = setInterval(() => {
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                    clearInterval(myInterval);
                }, 500);
                
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        zoomDaySelected(){
            this.focus = this.daySelected;
            this.type = 'day'
            this.optionDayEvent = false
            this.singleOptionDayEvent = false
        },
        loadDataCalendar () {

            // this.events = this.dataDummy

            this.events = [];

            axios.get(process.env.VUE_APP_API_URL + 'meetingroom/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                // console.log(response.data);

                let storeDat = [];
                let data = response.data

                for(let i in data){
                    data[i]["color"] = data[i]["color"].toLowerCase();

                    for(let x in this.allUsers){
                        if (this.allUsers[x].idemp == data[i].idemp) {
                            data[i]["userApply"] = this.allUsers[x].name;
                            data[i]["userContact"] = this.allUsers[x].phone;
                        }
                    }
                    
                    if(data[i].bookingstatus != "CANCELLED"){
                        storeDat.push(data[i]);
                    }
                    
                    // this.allUsers
                }

                this.events = storeDat;
                // console.log(this.events);
                // console.log(this.allUsers);
                
            })
            .catch(error => {
                console.log(error.response);
            })

            

        },

        loadListAvailableRoom(startDt,endDt,startT,endT){
            this.radioRoomType = [];
            this.imgRoomSelected = require('@/assets/img/officeCar/none.jpg');

            if (this.radioRoomType.length == 0) {
                this.visibleRoom = true;
            }

            axios.get(process.env.VUE_APP_API_URL + 'meetingroom/cekroom?startdt='+startDt+'T'+startT+':00&enddt='+endDt+'T'+endT+':00', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let data = response.data
                console.log(data);
                for (let i = 0; i < data.length; i++) {
                    for (let x = 0; x < this.listRoom.length; x++) {
                        if (data[i].idmeetingroomtype == this.listRoom[x].id) {
                            data[i]["label"] = this.listRoom[x].label;
                            data[i]["calendarColor"] = this.listRoom[x].calendarColor;
                            data[i]["colorCheckbox"] = this.listRoom[x].colorCheckbox;
                            data[i]["id"] = this.listRoom[x].id;
                        }
                    }
                }

                this.radioRoomType = data;
                // for(let i in data){
                //     if(data[i].name != "SPW 01 Meeting Room"){
                //         this.radioRoomType.push(data[i])
                //     }
                // }


                if (this.radioRoomType.length != 0) {
                    this.visibleRoom = false;
                }
                
            })
            .catch(error => {
                console.log(error);
            })

            // console.log(process.env.VUE_APP_API_URL + 'meetingroom/cekroom?startdt='+startDt+'T'+startT+':00&enddt='+endDt+'T'+endT+':00');
        },


        // Process Fill Form Meeting Room Booking & Submit
        cancelFillBooking(){
            this.$refs.form.reset()
            this.dialogAddDateMeetingRoomBook = false
            this.roomNotChoose = true;
            this.assistRequired = false;
            this.assistRefreshment = false;
        },

        checkAvailableRoom(a,endd){ //eslint-disable-line

            this.roomNotChoose = true;

            if (Date.parse(this.startDate) > Date.parse(this.endDate)) {
                this.endDate = this.startDate;
            }

            // console.log("Start Date: "+this.startDate);
            // console.log("End Date: "+this.endDate);
            // console.log("Start Time: "+this.startTime);
            // console.log("End Time: "+this.endTime);

            if (endd == "dt") {
                if(Date.parse(this.startDate+"T"+this.startTime) < Date.parse(this.endDate+"T"+this.endTime)){
                    //start is less than End
                }else{
                    //end is less than start
                    this.textWarning = "Please select valid end datetime.";
                    this.dialogWarning = true;
                }
            }

            this.loadListAvailableRoom(this.startDate,this.endDate,this.startTime,this.endTime)
        },

        chooseRoom: function(roomSelected){
            this.objectPostDataForm.iDselectedRoom = roomSelected.id;
            this.objectPostDataForm.labelColor = roomSelected.calendarColor;
            // console.log(roomSelected.id);
            this.roomNotChoose = false;
            // console.log(roomSelected);

            for(let i in this.listAllRooms){
                if (this.listAllRooms[i].id == roomSelected.id) {
                    this.imgRoomSelected = this.listAllRooms[i].src;
                }
            }

        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        submitRoomBooking(){

            // console.log("siall");

            // console.log(this.assistRefreshment);
            // console.log(this.objectPostDataForm.needRefresh);

            if(Date.parse(this.startDate+"T"+this.startTime) < Date.parse(this.endDate+"T"+this.endTime)){
                // console.log("a");
                //start is less than End
                if (this.assistRequired == true && (this.objectPostDataForm.needSupport == null || this.objectPostDataForm.needSupport.trim().length == 0)) {
                    let self = this
                    setTimeout(function () {
                        if (self.$refs.form.validate()){
                        //other codes
                        // alert('submitted')
                        }  
                    })
                    this.textWarning = "Please fill out all of required field.";
                    this.dialogWarning = true;
                    // console.log("c");
                }
                else if (this.assistRefreshment == true && (this.objectPostDataForm.needRefresh == null || this.objectPostDataForm.needRefresh.trim().length == 0)) {
                    let self = this
                    setTimeout(function () {
                        if (self.$refs.form.validate()){
                        //other codes
                        // alert('submitted')
                        }  
                    })
                    this.textWarning = "Please fill out all of required field.";
                    this.dialogWarning = true;
                    // console.log("d");
                }
                // else if (this.assistRequired == true) {

                //     console.log("e");

                //     if(this.objectPostDataForm.needSupport.trim().length == 0){
                //         let self = this
                //         setTimeout(function () {
                //             if (self.$refs.form.validate()){
                //             //other codes
                //             // alert('submitted')
                //             }  
                //         })
                //         this.textWarning = "Please fill out all of required field.";
                //         this.dialogWarning = true;
                //         console.log("f");
                //     }
                    
                // }
                // else if (this.assistRefreshment == true) {

                //     console.log("g");

                //     if(this.objectPostDataForm.needRefresh.trim().length == 0){
                //         let self = this
                //         setTimeout(function () {
                //             if (self.$refs.form.validate()){
                //             //other codes
                //             // alert('submitted')
                //             }  
                //         })
                //         this.textWarning = "Please fill out all of required field.";
                //         this.dialogWarning = true;
                //         console.log("h");
                //     }
                    
                // }
                else{

                    // console.log("i");

                    if( this.objectPostDataForm.iDselectedRoom != null
                    && this.objectPostDataForm.eventTitle != ''
                    && this.objectPostDataForm.eventPax != ''){

                        // console.log("j");

                        if( this.objectPostDataForm.iDselectedRoom != null
                        && this.objectPostDataForm.eventTitle != null
                        && this.objectPostDataForm.eventPax != null){

                            // console.log("k");
                        
                            let jsonBookMeetingRoom = {
                                idemp: this.$store.getters.user.idEmp,
                                idmeetingroomtype: this.objectPostDataForm.iDselectedRoom,
                                event: this.objectPostDataForm.eventTitle,
                                description: this.objectPostDataForm.eventDescription,
                                startdatetime: this.startDate + "T" + this.startTime + ":00",
                                enddatetime: this.endDate + "T" + this.endTime + ":00",
                                eventpax: this.objectPostDataForm.eventPax,
                                assistanceremarks: this.objectPostDataForm.needSupport,
                                refreshmentsremarks: this.objectPostDataForm.needRefresh,
                                assistanceStatus: "",
                                refreshmentsstatus: "",
                                bookingstatus: "BOOKED",
                            }


                            // Control Status Assist
                            if (jsonBookMeetingRoom.assistanceremarks != ""  && jsonBookMeetingRoom.assistanceremarks != null) {

                                if(this.assistRequired == false){
                                    jsonBookMeetingRoom.assistanceStatus = "NO";
                                }
                                else{
                                    jsonBookMeetingRoom.assistanceStatus = "YES";
                                }
                                
                            }
                            else{
                                jsonBookMeetingRoom.assistanceStatus = "NO";
                            }

                            // Control Status Refresh
                            if (jsonBookMeetingRoom.refreshmentsremarks != "" && jsonBookMeetingRoom.refreshmentsremarks != null) {

                                if(this.assistRefreshment == false){
                                    jsonBookMeetingRoom.refreshmentsstatus = "N/A";
                                }
                                else{
                                    jsonBookMeetingRoom.refreshmentsstatus = "PENDING";
                                }
                                
                            }
                            else {
                                jsonBookMeetingRoom.refreshmentsstatus = "N/A";
                            }

                            
                            console.log(jsonBookMeetingRoom);
                            let jsonString = JSON.stringify(jsonBookMeetingRoom);
                            // console.log(jsonString);

                            axios.post(process.env.VUE_APP_API_URL + "meetingroom/book",jsonString,
                            {
                                headers: {
                                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                }
                            })
                            .then(response => {//eslint-disable-line
                                // console.log(response);
                                this.textSuccess = "book";
                                this.dialogSuccessMeetingRoomBook = true;

                                
                                this.loadDataCalendar();
                                bus.$emit('changeIt', 'loadNotify');
                                this.$refs.form.reset();
                            })
                            .catch(e => {
                                console.log(e.response);
                                this.dialogSuccessMeetingRoomBook = false;
                                this.dialogErrorMeetingRoomBooking = true;
                                // window.loadDt = false;
                            })
                        
                        }
                        else{
                            let self = this
                            setTimeout(function () {
                                if (self.$refs.form.validate()){
                                //other codes
                                // alert('submitted')
                                }  
                            })
                            this.textWarning = "Please fill out all of required field.";
                            this.dialogWarning = true;
                        }

                        


                    }else{
                        // console.log(this.rules.eventTitle);
                        let self = this
                        setTimeout(function () {
                            if (self.$refs.form.validate()){
                            //other codes
                            // alert('submitted')
                            }  
                        })
                        this.textWarning = "Please fill out all of required field.";
                        this.dialogWarning = true;
                    }

                }

            }else{
                console.log("b");
                //end is less than start
                this.textWarning = "Please select valid end datetime."
                this.dialogWarning = true
            }

            


            

        },


        // View Details Booking
        viewDetailsMyBooking(data){

            // console.log(data);
            // console.log(this.listRoom);

            let keyData = Object.keys(data)
            for(let i in this.displayDetails){

                for(let x in keyData){
                    if (this.displayDetails[i].attribute == keyData[x]) {
                        
                        if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
                            this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
                        }
                        else if (keyData[x] == "description") {
                            if (data.description == "" || data.description == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "userContact") {
                            if (data.userContact == "" || data.userContact == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "assistancestatus") {
                            if (data.assistancestatus == "YES") {
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                            else if (data.assistancestatus == "NO") {
                                this.displayDetails[i].value = "N/A";
                            }
                        }
                        else if (keyData[x] == "Assistanceremarks") {
                            if (data.Assistanceremarks == "" || data.Assistanceremarks == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "refreshmentsremarks") {
                            if (data.refreshmentsremarks == "" || data.refreshmentsremarks == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "idmeetingroom") {
                            for(let y in this.listRoom){
                                if (data.idmeetingroom == this.listRoom[y].id) {
                                    this.displayDetails[i].value = this.listRoom[y].label;
                                }
                            }
                        }
                        else{
                            this.displayDetails[i].value = data[keyData[x]];
                        }
                    }
                }

            }

            // console.log(this.displayDetails);

            this.dialogDisplayDetails = true;

        },

        controlColorFont(status){
            if (status == "APPROVED") {
                return "white--text text-bold" 
            }
            if (status == "REJECTED") {
                return "white--text text-bold" 
            }
            else if(status == "PENDING"){
                return "white--text text-bold" 
            }
        },

        controlBgColor(status){
            if (status == "APPROVED") {
                return "approve" 
            }
            if (status == "REJECTED") {
                return "reject" 
            }
            else if(status == "PENDING"){
                return "pending" 
            }
        },


        // Cancel/Delete Booking
        cancelBooking(data){
            // console.log(data);

            // check id meeting room
            let idRoom = null;
            for(let i in this.listColorRoom){
                if(this.listColorRoom[i].room == data.name){
                    idRoom = this.listColorRoom[i].id;
                }
            }

            let payloadEdit = {
                idemp: data.idemp,
                idmeetingroomtype: idRoom,
                event: data.event,
                description: data.description,
                startdatetime: data.start,
                enddatetime: data.end,
                eventpax: data.eventpax,
                assistanceremarks: data.Assistanceremarks,
                refreshmentsremarks: data.refreshmentsremarks,
                assistanceStatus: "",
                refreshmentsstatus: "N/A",
                bookingstatus: "CANCELLED",
            }

            console.log(payloadEdit);

            let jsonString = JSON.stringify(payloadEdit);
            console.log(jsonString);

            axios.put(process.env.VUE_APP_API_URL + 'meetingroom/editbook',jsonString,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                bus.$emit('changeIt', 'loadNotify');
                this.textSuccess = "cancelled";
                this.dialogSuccessMeetingRoomBook = true;
                this.loadDataCalendar();
            })
            .catch(e => {
                console.log(e.response);
                this.dialogSuccessMeetingRoomBook = false;
                this.dialogErrorMeetingRoomBooking = true;
                // window.loadDt = false;
            })

            // Uncomment code below kalau nk pakai API delete list meeting room ====
            // axios.get(process.env.VUE_APP_API_URL + 'meetingroom/cancelbook?idmeetingroomtype='+data.idmeetingroom+'&startdate='+data.start+'&enddate='+data.end,
            // {
            //     headers: {
            //     "Authorization": 'Bearer ' + this.$store.getters.user.token,
            //     }
            // })
            // .then(response => { //eslint-disable-line
            //     // console.log(response);
            //     this.textSuccess = "deleted";
            //     this.dialogSuccessMeetingRoomBook = true;
            //     this.loadDataCalendar();
            //     bus.$emit('changeIt', 'loadNotify');
            // })
            // .catch(e => {
            //     console.log(e.response);
            //     this.dialogSuccessMeetingRoomBook = false;
            //     this.dialogErrorMeetingRoomBooking = true;
            //     // window.loadDt = false;
            // })

            // console.log(process.env.VUE_APP_API_URL + 'meetingroom/cancelbook?idmeetingroomtype='+data.idmeetingroom+'&startdate='+data.start+'&enddate='+data.end);

        }

    },
    mounted(){
        this.load();
        this.objectPostDataForm.userApply = this.$store.getters.user.name;
        this.objectPostDataForm.userContact = this.$store.getters.user.phone;
    },
    created (){
        bus.$on('changeIt', (data) => {

            // console.log(data);

            if (data == "loadDataCalendar") {
                this.loadDataCalendar();
            }

        })
    }

}

</script>

<style lang="scss">
@import '~scss/main';



</style>